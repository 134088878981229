.subscription-panel {
  color: #333;
    .card {
        border: none;
        border-radius: 1rem;
        transition: all 0.2s;
        box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
      }
      .sale{
        z-index: 3;
        position: absolute;
        width: 60px;
        height: 60px;
        border: none;
        top: 30px;
        right: 15px;
        display: flex;
        flex-direction: row;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        background: radial-gradient(135.43% 1312% at -27.21% -40%, rgb(255, 221, 134) 0%, rgb(231, 110, 154) 100%);
        box-shadow: rgb(0, 0, 0) 0px 0px 3px inset, rgb(255, 255, 255) 0px 0px 13.5px inset;
        border-radius: 92px;
        transform: rotate(15deg);
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 0.01em;
        text-align: center;
        color: rgb(255, 255, 255);
        cursor: default;
        user-select: none;
      }
      .banner {
        align-items: center;
        background-image: linear-gradient(92deg,rgba(214,165,253,.9),rgba(137,99,245,.9) 72%);
        border-radius: 3px 3px 20px 20px;
        display: flex;
        flex-direction: column;
        height: 24px;
        justify-content: flex-start;
        left: 0;
        margin: 0 auto;
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content;
        min-width: 70px;
        position: absolute;
        right: 0;
        top: -1px;
        z-index: 3;
      }
      .card-title {
        margin: 0.5rem 0;
        font-size: 0.9rem;
        letter-spacing: 0.1rem;
        font-weight: bold;
      }
      
      .card-price {
        font-size: 3rem;
        margin: 0;
      }
      
      .card-price .period {
        font-size: 0.8rem;
      }
      
      ul {
        list-style-type: none;
      }
      
      li.check::before {
        content: "✓ ";
        color:  #4e73df;
      }
      
      li.text-muted::before {
        content: "✘ ";
        color: red;
      }
      
      .text-muted {
        opacity: 0.7;
      }
      .strikethrough {
        text-decoration: line-through;
        margin-right: 5px;
      }
      
// Existing styles
.btn {
    cursor: pointer;
    font-size: 80%;
    border-radius: 5rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
    padding: 1rem;
    transition: all 0.2s;
  }
  
  // New styles to handle disabled state
  .btn:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
      .bannerText{
        margin-top: 0.2rem;
        padding: 2px 15px 0px;
      font-size: 18px;
      line-height: 14px;
      font-family: PPPangramSansRounded-Bold, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
      white-space: nowrap;
      color: rgb(255, 255, 255);
      }

}