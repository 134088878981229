.video-modal {

    .video-grid {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
      }
      p {
        margin: 10px 0;
        color: #333;
        font-size: 14px;
      }
      .new-videos-info {
        text-align: center;
        font-size: 16px;
        color: #f44336; // Example: bright red color for attention
        margin-bottom: 20px;
      }
      .ant-modal-content {
        border-radius: 10px;
        overflow: hidden;
      }
      .video-card {
        width: 100%; /* Full width on mobile */
        max-width: 300px; /* Default maximum width - adjust as needed */
        border-radius: 10px;
      }
      
      .video-container {
        position: relative;
        padding-top: 56.25%; /* 16:9 Aspect Ratio */
      }
      
      .video-container > div {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
      
      .video-blur-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(8px);
      }
      
      .ant-card-body {
        padding: 12px;
      }
      
      .ant-btn-primary {
        background-color: #1890ff;
        border-color: #1890ff;
        color: white;
      }
      
      /* Responsive adjustments for smaller screens */
      @media (max-width: 600px) {
        .video-modal .ant-modal-content {
          width: 95% !important;
        }
      }
      
      /* Styles for larger screens (PC) */
      @media (min-width: 1024px) {
        .video-modal .ant-modal-content {
          width: 80% !important; /* Adjust the modal width as needed */
        }
      
        .video-card {
          max-width: 350px; /* Adjust the size of video cards on larger screens */
        }
      
        .video-container {
          padding-top: 56.25%; /* Maintain the 16:9 Aspect Ratio */
        }
      }
    }
