.video-buttons {
  display: flex;
  align-items: center;
  .video-button {
    cursor: pointer;
    margin: 0.8rem 0.5rem;
    border: none;
    border-radius: 24px;
    font-family: PPPangramSansRounded-Bold, moderat;
    line-height: 20px;
    font-size: 16px;
    text-align: center;
    padding: 8px 13px;
    background: linear-gradient(180deg, rgba(224, 224, 224, 0) 0%, #d9d9d9 100%), rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.9), inset 0px 0px 5px rgba(255, 255, 255, 0.8);
    color: #5e5e5e;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: box-shadow 0.3s ease;
    max-width: 220px; /* Consider adjusting for smaller screens */
    max-height: 80px; /* Consider adjusting for smaller screens */
    outline: none; /* Remove focus outline */
  }
  
  .video-button:hover {
    box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.9), inset 0px 0px 5px rgba(255, 255, 255, 0.8);
  }
  
  .disabled-button {
    cursor: not-allowed;
    opacity: 0.5;
    background: #e0e0e0;
    color: #888787;
    box-shadow: none;
  }
  
  @media (max-width: 768px) {
    .video-button {
      padding: 6px 12px;
      font-size: 14px; /* Smaller font size for mobile */
      max-width: 150px; /* Smaller max-width for mobile */
      max-height: 60px; /* Smaller max-height for mobile */
    }
  }
}
.tooltip-container {
  position: relative;
  margin-right: 10px;
}
.resetButton{
}
.startStoryButton {
  z-index: 1;
  cursor: pointer;
  border: none;
  border-radius: 24px;
  align-self: center;
  font-family: PPPangramSansRounded-Bold, moderat;
  line-height: 20px;
  font-size: 15px;
  text-align: center;
  padding: 10px 20px;
  background: linear-gradient(135deg, #64c7c0 0%, #6699cc 100%);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2),
    inset 0px 0px 5px rgba(0, 0, 0, 0.1);
  color: #fff;
  text-shadow: 2px 2px 3px #0005;
  opacity: 1;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  z-index: 100;
  animation: glowing 1300ms infinite;
}

.startStoryButton:hover {
  box-shadow: 0px 0px 30px rgba(255, 215, 0, 0.6),
    inset 0px 0px 10px rgba(255, 215, 0, 0.3);
  transform: scale(1.05);
}

.startStoryButton:active {
  transform: scale(0.95);
}

@keyframes glowing {
  0%, 100% { box-shadow: 0 0 15px rgba(255, 215, 0, 0.7); }
  50% { box-shadow: 0 0 25px rgba(255, 215, 0, 0.9); }
}
.prompt-input {
  display: flex;
  align-items: center;
}
.prompt-input input {
  width: 100%; /* Full width */
  border: none; /* No border */
  box-shadow: inset 0 0 10px rgba(0,0,0,0.2); /* Inner shadow for depth */
  border-radius: 25px; /* Rounded corners */
  padding: 10px 20px; /* Padding inside the input */
  font-size: 16px; /* Font size */
  background-color: rgba(255, 182, 193, 0.2); /* Semi-transparent pink background */
  color: #333; /* Text color */
  outline: none; /* Remove default focus outline */
  transition: box-shadow 0.3s ease-in-out; /* Smooth transition for shadow */
  color: white;
}

.prompt-input input::placeholder {
  color: lightgray;
}

.prompt-input input:focus {
  box-shadow: inset 0 0 10px rgba(0,0,0,0.4); /* Darker shadow on focus */
}
