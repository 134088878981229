.unlock-character-modal {
  .ant-modal-content {
    background: rgba(17, 25, 40, 0.95);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    padding: 20px;
    width: 100%;
    max-width: 500px;
    position: relative;
  }

  .ant-modal-header {
    background: transparent;
    border-bottom: none;
    padding: 16px 24px;
    
    .ant-modal-title {
      color: #ffffff;
      font-size: 1.5em;
      font-weight: 600;
      text-align: center;
    }
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    color: #ffffff;

    .character-preview {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 20px;
      border: 3px solid rgba(255, 255, 255, 0.2);
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      font-size: 1.2em;
      margin: 16px 0;

      .textgem {
        width: 20px;
        height: 20px;
        vertical-align: middle;
      }
    }
  }

  .ant-modal-footer {
    border-top: none;
    padding: 16px 24px;
    text-align: center;
    
    .ant-btn {
      margin: 0 8px;
      border-radius: 6px;
      padding: 8px 24px;
      height: auto;
      font-size: 1em;
      
      &-primary {
        background: #4f46e5;
        border-color: #4f46e5;
        
        &:hover {
          background: #4338ca;
          border-color: #4338ca;
        }
      }
    }
  }

  // Animation
  @keyframes modalFadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .ant-modal {
    animation: modalFadeIn 0.3s ease-out;
  }
}