  // Color Palette based on the provided gradient
  $primary-light: #5C75CC;
  $primary-dark: #203378;
  $background: #F0F0F0;
  $light-grey: #ECECEC;
  $dark-grey: #505050;
  $white: #FFFFFF;
  $black: #000000;

// Basic Reset for Modal Elements
.video-modal {

// Font
$font-family: PPPangramSansRounded-Bold, moderat;
  font-family: $font-family;
  // Modal Background
.modal-background {
  background: $background;
  padding: 20px;
}

// Header with Gradient
.modal-header {
  background: linear-gradient(180deg, $primary-light, $primary-dark);
  color: $white;
  padding: 15px;
  border-radius: 10px 10px 0 0;
  text-align: center;
  font-size: 1.2em;
}

// Modal Body
.modal-body {
  background: $white;
  padding: 20px;
  color: $dark-grey;
}

// Video Grid
.video-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

}

// Video Card
.video-card {
  background: $light-grey;
  border: 1px solid $dark-grey;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba($black, 0.2);
  }

  .video-thumbnail {
    width: 100%;
    height: 180px;
    background-color: $dark-grey;
  }

  .video-info {
    padding: 10px;
    p {
      margin-bottom: 10px;
    }
  }
}
// Video Blur Overlay
.video-blur-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($white, 0.8); // Semi-transparent white overlay
  backdrop-filter: blur(5px); // Blur effect
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;

  &:before {
    content: '🔒 Locked'; // Icon/Text indicating locked status
    display: block;
    font-size: 1.5em;
    color: $primary-dark;
  }

  &:hover {
    background: rgba($white, 0.9); // Slightly more opaque on hover
  }
}

// Unlock Button
.unlock-button {
  background-color: $primary-dark; // Primary color for the button
  color: $white;
  padding: 12px 20px;
  border: none;
  border-radius: 5px; // Smoothed corners1
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s, transform 0.2s;

  &:hover {
    background-color: lighten($primary-dark, 10%); // Lighten on hover
    box-shadow: 0 4px 8px rgba($black, 0.15); // Subtle shadow for depth
  }

  &:active {
    background-color: darken($primary-dark, 10%); // Darken when clicked
    transform: translateY(2px); // Slight downward shift on click
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba($primary-light, 0.5); // Focus outline
  }
}
// Responsive Design
@media (max-width: 768px) {
  .video-grid {
    grid-template-columns: 1fr;
  }
}

