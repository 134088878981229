.chat-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    width: 100%;
  }
  
  /* Style for the Input component's container */
  .chat-input-container > :last-child {
    flex: 1;
    min-width: 60%; /* Ensure input maintains a minimum width */
    max-width: 800px; /* Maximum width for larger screens */
  }
  
  .pro-unlock-banner {
    max-width: 300px;
    flex: 0 0 auto; /* Don't grow, don't shrink, use auto basis */
    transition: all 0.3s ease;
    margin-right: 1rem; /* Space between banner and input */
  }
  
  /* Responsive adjustments */
  @media (max-width: 1200px) {
    .pro-unlock-banner {
      max-width: 250px;
  
      .banner-description {
        -webkit-line-clamp: 1;
      }
    }
  }
  
  @media (max-width: 1000px) {
    .pro-unlock-banner {
      max-width: 200px;
    }
  }
  
  @media (max-width: 900px) {
    .pro-unlock-banner {
      max-width: 180px;
    }
    
    .chat-input-container > :last-child {
      min-width: 50%; /* Adjust minimum width for smaller screens */
    }
  }
  
  @media (max-width: 768px) {
    .pro-unlock-banner {
      display: none;
    }
    
    .chat-input-container > :last-child {
      min-width: 80%; /* More width for input when banner is hidden */
    }
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .animate-slideIn {
    animation: slideIn 0.5s ease-out;
  }