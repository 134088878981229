/* tailwind.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Animations and Utilities */
@layer utilities {
  /* Shimmer Animation */
  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }

  .animate-shimmer {
    animation: shimmer 2s infinite;
    background: linear-gradient(
      to right,
      rgba(124, 58, 237, 0.1) 0%,
      rgba(124, 58, 237, 0.3) 50%,
      rgba(124, 58, 237, 0.1) 100%
    );
    background-size: 2000px 100%;
  }

  /* Float Animation */
  @keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }

  .animate-float {
    animation: float 3s ease-in-out infinite;
  }

  /* Twinkle Animation */
  @keyframes twinkle {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }

  .animate-twinkle {
    animation: twinkle 1s ease-in-out infinite;
  }

  /* Shine Animation */
  @keyframes shine {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }

  .animate-shine {
    animation: shine 1s ease-in-out infinite;
  }

  /* Optional: Pulse Animation (Tailwind already has animate-pulse) */
  /* You can customize it if needed */
  /* 
  @keyframes pulse-custom {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }

  .animate-pulse-custom {
    animation: pulse-custom 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  */
}