.infoButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: white; /* or any color you want */
  }
  
  
  .modalContent {
    /* add your own styles here */
  }