.play-layer-wrapper {
  position: relative;
}
.voice-memo-player{
  width: 200px;
  margin: 0;
  box-sizing: border-box;
}
.play-layer {
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100vh;
}

.sound-wave {
  width: 100%;
  min-height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  svg {
    display: block;
    max-width: 63rem;
    height: auto;
  }
}
.sound-wave:after {
  content: "";
  position: absolute;
  width: 100%;
  z-index: -1;
  top: 0;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  background: radial-gradient(#1389e933, transparent 60%, transparent 100%);
}
.progress-bar-container {
  position: relative;
  width: 35%;
  margin: -2rem auto 6rem;
}

.progress-bar {
  height: 10px;
  border-radius: 30px;
  border: 2px solid #fff;
  background-color: #fff;
  overflow: hidden;
}

.progress-color {
  background: #1389e9;
  height: 100%;
  width: 0;
  border-radius: 30px;
  transition: width 0.2s ease-out;
}

.lock-icon {
  opacity: 1;
  color: #0e2a47;
  position: absolute;
  top: -15px; /* Adjust as needed */
  left: 10%; /* Lock point */
  /* Additional styling for the lock icon */
}
.d-none {
  display: none;
}

@keyframes scale1 {
  0% {
    transform: scaleY(0.6);
  }
  40% {
    transform: scaleY(1.2);
  }
  100% {
    transform: scaleY(0.6);
  }
}

.bar-scale1 {
  animation: scale1 0.6s infinite;
  transform-origin: center;
}

@keyframes scale2 {
  0% {
    transform: scaleY(0.5);
  }
  30% {
    transform: scaleY(1.6);
  }
  100% {
    transform: scaleY(0.5);
  }
}

.bar-scale2 {
  animation: scale2 0.6s infinite;
  transform-origin: center;
}

@keyframes scale3 {
  0% {
    transform: scaleY(1.5);
  }
  70% {
    transform: scaleY(0.5);
  }
  100% {
    transform: scaleY(1.5);
  }
}

.bar-scale3 {
  animation: scale3 0.6s infinite;
  transform-origin: center;
}

.stop-animation {
  animation-duration: 0s;
}

.audio-buttons {
  margin-right: 8rem;
  position: relative;
}

.play-button {
  svg {
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.65);
    border-radius: 50px;
  }
}
.play-button,
.pause-button {
  width: 46px;
  height: 46px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
