.level-unlock-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(62, 84, 163, 0.9); // Adjusted to match your theme
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s ease-in-out;
  
    &.visible {
      visibility: visible;
      opacity: 1;
    }
  
    .level-info {
      text-align: center;
      margin-bottom: 20px;
      color: #FFFFFF; // Text color for readability
  
      h1 {
        font-size: 2.5em;
        color: #E4E4FF; // Lighter shade for emphasis
      }
  
      h2 {
        font-size: 1.8em;
        color: #CCD1FF; // Slightly different shade for level details
      }
  
      p {
        max-width: 90%; // Ensuring it doesn't stretch too far on mobile
        margin: auto;
        color: #BBBCCF; // Subtle text color for descriptions
      }
    }
  
    .characters-row {
      display: flex;
      justify-content: center;
      gap: 10px; // Reduced gap for mobile
    }
  
    .character-card {
        display: flex; // Use flexbox for layout
        flex-direction: column; // Stack image and text vertically
        align-items: center; // Center-align children horizontally
        justify-content: center; // Center-align children vertically
        background: #6C73B1;
        border-radius: 12px;
        padding: 15px;
        text-align: center;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        transition: transform 0.3s ease;
        position: relative; // Set the position context for absolute children
  
        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
        }
  
        .character-image {
          position: relative; // Adjust the position to be relative within the card
          width: 100%; // Ensure the image container takes up the full width of the card
          img {
            width: 80px; // Adjust image width
            height: auto; // Ensure the height is auto to maintain aspect ratio
            border-radius: 50%;
            margin-bottom: 10px; // Space between image and text
            object-fit: cover; // Ensure the image covers the area without distortion
            display: block; // Change display to block to remove bottom space
            margin: 0 auto; // Auto margins for horizontal centering
          }
        }
  
        h3 {
          font-size: 1em;
          color: #FFFFFF;
          margin: 0; // Remove any default margins
        }
      }
  
  
    .continue-button {
      background-color: #4A90E2; // Button color to match your theme
      color: white;
      border: none;
      border-radius: 20px;
      padding: 10px 20px;
      margin-top: 1rem;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #6AA6D6; // Lighter shade on hover
      }
    }
  }
  
  @media (max-width: 768px) {
    .level-info h1 {
      font-size: 2em; // Slightly smaller on tablets
    }
  
    .characters-row {
      gap: 5px; // Smaller gap for better fit
    }
  
    .character-card {
      width: 30%; // Take up one third of the container width
      padding: 10px; // Reduced padding
    }
  }
  
  @media (max-width: 480px) {
    .character-image img {
        width: 60px; // Adjust image width for mobile
        height: 60px; // Adjust image height for mobile
      }
    .level-unlock-overlay {

    padding: 10px; // Add padding around the modal for mobile
    align-items: center; // Center align items for better focus
    height: 100%; // Full screen height
    overflow-y: auto; // Allow scrolling on small screens

    .level-info {
      margin-top: 20px; // Add some space on top
      h1 {
        font-size: 1.5em; // Smaller font size for mobile
      }
      h2 {
        font-size: 1.2em; // Smaller font size for mobile
      }
      p {
        font-size: 0.9em; // Smaller font size for mobile
      }
    }

    .characters-row {
      align-items: center; // Center-align character cards
    }

    .character-card {
      width: 100%; // Make character cards fill more width on mobile
      margin-bottom: 10px; // Add space between character cards
    }

    .character-image img {
      width: 60px; // Smaller images on mobile
      height: 60px; // Keep the aspect ratio
    }
    .continue-button {
font-size: 1em; // Button font size for mobile
padding: 12px 24px; // Sufficient padding for a touch target
  margin-top: 20px; // Space from the last character card
  width: auto; // Auto-width to fit content
  min-width: 150px; // Minimum width for easy tapping
  }
  }
}
.gems-earned {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em; // Slightly larger for more emphasis
    margin-top: 10px; // Space from the level title

    .gem-icon {
        width: 35px; // Slightly larger for visibility
        height: auto;
        margin-right: 10px; // Space between icon and text
    }

    span {
        font-weight: bold; // Make the gems count stand out
        color: #FFD700; // A vibrant gold color for high visibility and dopamine effect
        animation: pop-in 0.6s ease forwards; // An animation to make the gems pop
        text-shadow: 0px 0px 8px rgba(255, 215, 0, 0.7); // A subtle glow effect for added pop
    }
}
  
  // Keyframes for the pop-in animation
  @keyframes pop-in {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    60% {
      transform: scale(1.2);
      opacity: 1;
    }
    100% {
      transform: scale(1);
    }
  }
  .level-unlock-overlay .star-icon {
    font-size: 3rem; /* Increase the size of the star */
    color: white; /* Set the color to white or any other color that suits your design */
    margin: 10px 0; /* Add some margin for spacing */
}
.level-unlock-overlay .unlock-title {
  color: #ffcc00; /* A vibrant color for the title */
  text-align: center;
  font-size: 2rem; /* Larger font size */
  margin-bottom: 15px; /* Spacing after the title */
}

.level-unlock-overlay .unlock-description {
  color: white; /* Light color for better readability */
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.4;
}

.level-unlock-overlay .unlock-description span {
  display: block; /* Each sentence in its own line for better focus */
  margin-bottom: 10px; /* Spacing between sentences */
}

.level-unlock-overlay .congrats {
  font-weight: bold;
  font-size: 1.3rem;
}

.level-unlock-overlay .feature-text {
  font-style: italic; /* Styling for emphasis */
}

.level-unlock-overlay .personalize-text {
  color: #AED581; /* A soft, contrasting color */
}

.level-unlock-overlay .dive-in {
  color: #64B5F6; /* Another soft, contrasting color */
}

.level-unlock-overlay .star-icon {
  font-size: 3rem;
  color: #FFD54F; /* A golden color for the star */
  margin: 20px auto; /* Center the star */
  display: block;
}
