.voice-popup-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(10, 10, 30, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .voice-popup {
    background: #1c1c3c;
    border-radius: 20px;
    padding: 40px;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.5);
    width: 50%;
    max-width: 640px;
    box-sizing: border-box;
    color: #ffffff; /* White text for better readability */
  }
  
  .voice-popup h2 {
    font-family: 'PPPangramSansRounded-Bold', sans-serif;
    font-size: 2.5em;
    margin-bottom: 30px;
    text-align: center;
    letter-spacing: 1px;
  }
  
  .voice-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr)); /* Adjust the min size as needed */
    gap: 15px;
    max-height: 60vh; /* Adjust based on your needs */
    overflow-y: auto;
    padding: 0 20px; /* Add padding for scroll bar */
  }
  
  .voice-item {
    background: #29294d;
    border-radius: 10px;
    padding: 10px; /* Reduced padding */
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    transition: background-color 0.2s, transform 0.2s;
    cursor: pointer;
    text-align: left;
  }
  
  .voice-item.selected {
    background: #4d4d7c;
  }
  
  .voice-name {
    font-family: 'Moderat', sans-serif;
    font-size: 0.9em; /* Adjust font size for smaller items */
    margin-bottom: 5px; /* Add some space between the name and description */
  }
  
  .voice-description {
    font-family: 'Moderat', sans-serif;
    font-size: 0.8em;
    opacity: 0.85;
  }
  
  .voice-popup-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    gap: 15px; /* Adds space between buttons */
  }
  
  .voice-popup-actions button {
    border: none;
    padding: 15px 30px;
    font-family: 'Moderat', sans-serif;
    font-size: 1em;
    cursor: pointer;
    transition: all 0.3s ease;
    color: #ffffff;
    background: #45458b; /* Base color for buttons */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden; /* Ensures pseudo-elements don't overflow */
    z-index: 1;
  }
  
  .voice-popup-actions button:before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    transition: all 0.65s ease;
    z-index: -1;
  }
  
  .voice-popup-actions button:hover:before {
    left: 100%;
  }
  
  .voice-popup-actions button:active {
    transform: translateY(2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .voice-popup-actions button.cancel-button {
    background: #65658b; /* Different shade for cancel button */
  }
  
  .voice-popup-actions button.confirm-button {
    background: #45458b; /* Distinct shade for confirm button */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  }
  
  .voice-popup-actions button:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  /* Responsive considerations for smaller screens */
  @media (max-width: 768px) {
    .voice-popup-actions {
      flex-direction: column;
      align-items: stretch;
    }
  
    .voice-popup-actions button {
      width: 100%;
      margin-bottom: 10px;
    }
  
    /* Last button should not have a margin at the bottom */
    .voice-popup-actions button:last-child {
      margin-bottom: 0;
    }
  }
  @media (max-width: 768px) {
    .voice-popup {
      padding: 30px;
      width: 80%;
    }
  
    .voice-popup h2 {
      font-size: 2em;
    }
  
    .voice-item {
      padding: 15px;
    }
  
  }
  