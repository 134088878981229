.TagMain{
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
      
.available-generations-display {
    font-size: 1.2rem;
    color: white;
}
.style-selector-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f2f2f2;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin-bottom: 1rem;
    .style-selector-label {
        margin-bottom: 10px;
        font-size: 16px;
        color: #333;
        font-weight: 500;
    }
    
    .style-selector {
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 10px;
        background-color: white;
        font-size: 14px;
        color: #555;
    }
    
    .style-option {
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 8px;
        border-bottom: 1px solid #eee;
        overflow: hidden; /* Ensure content does not overflow */
    }
    
    .style-preview-image {
        max-width: 30px !important; /* Reduced size for consistency */
        max-height: 30px !important; /* Reduced size for consistency */
    }
    
    .style-label {
        flex-grow: 1;
        font-size: 14px;
        color: #333;
    }
    
    .style-unlocked, .style-cost {
        font-size: 12px;
        margin-left: 10px;
        padding: 2px 6px;
        border-radius: 12px;
        color: white;
    }
    
    .style-unlocked {
        background-color: #4caf50;
    }
    
    .style-cost {
        background-color: #f44336;
    }

}



.buy-generations-button{
    align-items: center;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    margin-top: 0.5rem;
    margin-bottom: 0.8rem;
    border: none;
    border-radius: 24px;
    font-family: PPPangramSansRounded-Bold, moderat;
    line-height: 20px;
    font-size: 14px;
    text-align: center;
    padding: 10px 20px;
    background: linear-gradient(
        180deg,
        rgba(224, 224, 224, 0) 0%,
        #d9d9d9 100%
      ),
      rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.9),
      inset 0px 0px 5px rgba(255, 255, 255, 0.8);
    color: #5e5e5e;
    opacity: 1;
    -webkit-transition: box-shadow 0.3s ease;
    transition: box-shadow 0.3s ease;
    max-width: 300px;
    max-height: 80px;
}
.buy-generations-button:hover {
    opacity: 1;
    box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.9),
      inset 0px 0px 5px rgba(255, 255, 255, 0.8);
  }

.style-selector-wrapper.open .style-options {

    opacity: 1;
    pointer-events: auto;
}

.style-option {
    padding: 12px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.style-option:hover {
    background-color: #f0f0f0;
}

.keyword-group {
    width: 100%;
    margin: 20px 0;
    position: relative;
    .lockTag{
        margin-inline: 0.2rem;
    }
}

.keywords-container {
    width: 100%;
}

.group-title {
    font-weight: bold;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 0.8rem;
    text-align: left;
    width: 100%;
    color: #FFFFFF;
    position: relative;
}

.imagePlaceholder{
    img{
        border-radius: 15px;
        width: 512px;
        height: 512px;
    }
    border-radius: 15px;
    width: 512px;
    height: 512px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    font-size: 20px;
    color: white;
    align-self: flex-end;
    
    &.text-only {
        max-width: auto;
        max-height: auto;
        padding: 20px;
      }
}
.PPChip {
    border: none;
    transition: background-color 0.3s ease;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 15px;
    display: inline-block;
    cursor: pointer;
    user-select: none;
}

.PPChip:hover {
    background-color: #356ABD;
}

.PPChip.bg-green-700 {
    background-color: #2C7A7B;
}

div[style*="backgroundGradient"] {
    position: relative;
}
.TagSelector{
    width: 50%;
}
.TagContent{
    margin-top: 1rem;
    width: 90%;
    margin-left: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.selected-keywords-container {
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    border-radius: 10px;
    max-width: 560px;
    min-height: 75px;
    padding: 10px;
    opacity: 0;
    visibility: hidden;
    transition: none;
    cursor: pointer;
    user-select: none;
    align-self:flex-start;
    opacity: 1;
    transform: translateY(0);
    margin-bottom: 1rem;
}

.selected-keywords-container.hidden {
    opacity: 0;
    transform: translateY(-10px);
    animation: fadeOutSlideDown 0.3s ease forwards;
}

.selected-keywords-container:not(.hidden) {
    animation: fadeInSlideUp 0.3s ease forwards;
}

@keyframes fadeInSlideUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOutSlideDown {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(20px);
    }
}

.show-keywords-container {
    opacity: 1;
    visibility: visible;
}

.waifu-title {
    font-size: 2em;
    margin-bottom: 20px;
    color: #FFFFFF;
    position: relative;
}

@keyframes keywordFadeIn {
    from {
        opacity: 0;
        transform: scale(0.7);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.selected-keywords-container .keyword.selected {
    background-color: #356ABD;
    border: none;
    transition: background-color 0.3s ease;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 15px;
    display: inline-block;
    cursor: pointer;
    user-select: none;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    border: 1px solid;
    border-radius: 0.375rem;
    color: #fff;
    background-color: #2C7A7B;
    position: relative; /* Added for the red cross effect */
}

.selected-keywords-container .keyword.selected::before,
.selected-keywords-container .keyword.selected::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 2px;
    background-color: red;
    transform-origin: center;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.selected-keywords-container .keyword.selected::before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.selected-keywords-container .keyword.selected::after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.selected-keywords-container .keyword.selected:hover::before,
.selected-keywords-container .keyword.selected:hover::after {
    opacity: 1;
}


.active-group-title {
    color: #239b2b;
}

.backgroundGradient {
overflow-y: auto;
overflow-x: hidden;
height: auto;
width: 100%;
min-height: 100vh;
background-image: linear-gradient(180deg, #5C75CC 0%, #203378 100%);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 20px 0;
user-select: none;
}
.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3); /* Light border color */
    border-top: 4px solid white; /* Top border color - make it white for visibility */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite; /* Animation for Safari */
    animation: spin 2s linear infinite;
    margin: auto; /* Center the spinner horizontally and vertically */
}

/* Keyframes for spin animation */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.selected-keywords-container.show-keywords-container ~ .generate-button {
    bottom: 70px; /* Adjust this value based on the height of your selected-keywords-container */
}


@keyframes gradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}
.tabs {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-bottom: 20px;
    width: 500px;
}

.tab {
    border-radius: 15px;
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid white;
    color: white;
    text-align: center;
    flex-grow: 1;
}

.active-tab {
    background-color: white;
    color: #203378;
}
@media only screen and (max-width: 768px) {
    .TagSelector {
        width: 100%;
    }
    .TagContent {
        margin-left: 0%;
        align-items: center;
        flex-direction: column-reverse;
        margin-bottom: 12rem;
    }
    .TagSelector{
        width: 95%;
    }
    .imagePlaceholder {
        img{
            width: 312px;
            height: 312px;
        }
        width: 312px;
        height: 312px;
        margin-bottom: 20px;
    }
    .tabs {
        width: 100%;
    }
}
}
.back-button {
    background: none;
    border: none;
    color: #FFF; /* White color for the icon */
    font-size: 1.5rem; /* Adjust size as needed */
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 0;
  }
.generate-button-wrapper,
.continue-button-wrapper {
    width: 100%;
    position: relative;
    bottom: auto;
    transform: none;
}
.continue-button {
    height: 60px;
    width: 200px;
    text-align: center;
    z-index: 10;
    animation: gradient 3s ease infinite alternate;
    background: linear-gradient(60deg, #4a90e2, #8e44ad, #e74c3c, #e67e22, #f1c40f, #2ecc71, #1abc9c, #34495e);
    background-size: 300% 300%;
    background-color: #2980b9;  /* Fallback color */
    border: none;
    border-radius: 8px;
    color: white;
    font-size: 18px;
    cursor: pointer;
    z-index: 10;
}
.continue-button-wrapper {
    margin-bottom: 1rem;
    height: 100px;
    width: 240px;
    border-radius: 10px;
    position: fixed;
    bottom: 0px;
    z-index: 9;
    background: hsla(0,0%,100%,.3);
    box-shadow: 0 0 30px hsla(0,0%,100%,.3);
    display: flex;  /* Enable Flexbox */
    justify-content: center;  /* Center horizontally */
    align-items: center;  /* Center vertically */
}
.lockTag{
    margin-inline: 0.2rem;
}
.gemIconTag{
    width: 25px;
    margin-inline: 0.2rem;
}
.generate-button {
    height: 60px;
    width: 160px;
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    border: none;
    border-radius: 8px;
    color: white;
    font-size: 22px;
    cursor: pointer;
}

.generate-button-wrapper {
    margin-bottom: 1rem;
    height: 100px;
    width: 200px;
    border-radius: 10px;
    position: fixed;
    bottom: 0px;
    background: hsla(0,0%,100%,.3);
    box-shadow: 0 0 30px hsla(0,0%,100%,.3);
    display: flex; 
    justify-content: center; 
    align-items: center; 

    &.up {
        bottom: 100px;
    }
}