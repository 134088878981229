.xp-bar-wrapper {
  text-align: center;
  font-family: PPPangramSansRounded-Bold;
}

.character-name {
  font-size: 1rem;
  color: white;; /* Spacing between name and XP bar */
}

.level-and-xp-bar {
  display: flex;
  align-items: center;
  gap: 5px;
}

.xp-bar-container {
  width: 50px;
  background-color: #ddd;
  border-radius: 12px;
  overflow: hidden;
  height: 8px;
}

/* Media query for screens wider than 1024 pixels */
@media screen and (min-width: 1024px) {
  .xp-bar-container {
    width: 90px;
  }
}


.xp-bar {
  height: 100%;
  background-color: white;
  transition: width 0.5s ease-in-out;
  box-shadow: 0 0 10px white, 0 0 20px white;
  animation: glowing 1.5s infinite ease-in-out;
}

@keyframes glowing {
  0% { box-shadow: 0 0 10px white, 0 0 20px white; }
  50% { box-shadow: 0 0 15px white, 0 0 25px white; }
  100% { box-shadow: 0 0 10px white, 0 0 20px white; }
}

.level-indicator {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 2px 6px;
  border-radius: 5px;
  font-size: 0.6rem;
}
