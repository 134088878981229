$primary-color: #2196F3;
$secondary-color: #BBDEFB;
$background-color: #F5F5F5;
$text-color: #333333;
$accent-color: #FF4081;
$card-background-color: #FFFFFF;
$card-border-color: #E0E0E0;

.call-interface {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: $background-color;

  .profile-section {
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: $card-background-color;
    border-bottom: 1px solid $card-border-color;

    .character-avatar {
      width: 80px;
      height: 80px;
      border-radius: 10px;
      object-fit: cover;
      margin-right: 20px;

      @media screen and (max-width: 480px) {
        width: 60px;
        height: 60px;
        margin-right: 10px;
      }
    }

    .character-info {
      h2 {
        font-size: 24px;
        color: $primary-color;
        margin-bottom: 5px;

        @media screen and (max-width: 480px) {
          font-size: 20px;
        }
      }

      p {
        font-size: 16px;
        color: $text-color;

        @media screen and (max-width: 480px) {
          font-size: 14px;
        }
      }
    }
  }

  .video-container {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;

    img {
      max-width: 100%;
      max-height: 500px;
      object-fit: cover;
      border-radius: 10px;
    }

    .call-overlay {
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      background-color: rgba(0, 0, 0, 0.6);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      @media screen and (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
      }

      .call-status {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .status-icon {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-right: 8px;

          &.on-call {
            background-color: $accent-color;
          }

          &.idle {
            background-color: #bdbdbd;
          }
        }

        p {
          font-size: 16px;
          color: #fff;

          @media screen and (max-width: 480px) {
            font-size: 14px;
          }
        }
      }

      .call-actions {
        .call-button, .mute-button {
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 16px;
          color: white;
          margin-inline-start: 1rem;

          @media screen and (max-width: 480px) {
            padding: 10px 20px;
            font-size: 16px;
          }

          &.start-call {
            background-color: $accent-color;
            color: #fff;

            &:hover {
              background-color: darken($accent-color, 10%);
            }
          }

          &.end-call {
            background-color: #f44336;
            color: #fff;

            &:hover {
              background-color: #e53935;
            }
          }
        }
      }
    }
  }

  .last-message {
    padding: 20px;
    background-color: $card-background-color;
    border-top: 1px solid $card-border-color;
    text-align: center;

    p {
      font-size: 16px;
      color: $text-color;

      @media screen and (max-width: 480px) {
        font-size: 14px;
      }
    }
  }
}