///
.hof-backToVotingBtn {
  display: block;
  margin: 0 auto; /* Center the button horizontally */
  padding: 10px 20px; /* Adjust padding as needed */
  background-color: #007bff; /* Adjust button background color */
  color: white; /* Adjust text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  text-align: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3; /* Darken the button on hover */
  }
}

.hof-hallOfFameWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.hof-sideBanner {
  position: fixed;
  top: 330px; /* Adjust this value to move the banners further down */
  width: 500px; /* Adjust width as needed for responsiveness */
  height: auto;
  z-index: 10; /* Keep banners behind the content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.hof-sideBanner img {
  width: 100%;
  height: auto;
}

.hof-laurelWreath {
  position: absolute;
  top: 1;
  left: 50%;
  transform: translateX(-50%);
  width: 150px; /* Adjust the width of the wreath as needed */
  height: 200px; /* New height for the wreath */
  object-fit: contain; /* Maintain aspect ratio of the GIF */
  z-index: 2; /* Make sure the wreath is above the banner */
}

.hof-left {
  left: 2%; /* Move the left banner closer to the center */
}

.hof-right {
  right: 2%; /* Move the right banner closer to the center */
}

.hof-contentWrapper {
  margin: 0 auto;
  max-width: 1000px; /* Maintain a maximum width */
  padding: 0 20px; /* Create padding between the content and the banners */
  z-index: 2; /* Ensure the content is above the banners */
}

@media (max-width: 1200px) {
  .hof-sideBanner {
    width: 250px; /* Reduce the banner size for smaller screens */
  }

  .hof-laurelWreath {
    width: 100px; /* Adjust the wreath size for smaller screens */
  }

  .hof-left {
    left: 5%; /* Adjust positioning for smaller screens */
  }

  .hof-right {
    right: 5%; /* Adjust positioning for smaller screens */
  }
}

@media (max-width: 768px) {
  .hof-sideBanner {
    display: flex;
    position: absolute;
    top: 150px; /* Move banners higher up on the screen */
    left: 50%;
    transform: translateX(-50%);
    width: 350px; /* Increase the banner size for mobile background */
    z-index: 1;
  }

  .hof-left, .hof-right {
    left: 50%;
    transform: translateX(-50%);
    top: 150px; /* Adjust for mobile */
  }

  .hof-laurelWreath {
    width: 100px; /* Keep the wreath size proportional */
    height: auto;
  }

  .hof-contentWrapper {
    max-width: 100%;
    padding: 0;
  }
}

@media (max-width: 480px) {
  .hof-sideBanner {
    width: 400px; /* Further increase the banner size for very small screens */
    top: 200px; /* Adjust placement for very small screens */
  }

  .hof-laurelWreath {
    width: 80px; /* Adjust wreath size for very small screens */
  }
}

///

.characterGrid {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-character-row {
  display: flex;
  justify-content: center; /* Center-aligns all three containers */
  align-items: center; /* Vertically aligns the containers */
  width: 100%;
  margin-bottom: 20px;
  gap: 80px; /* Add this line to control spacing between the containers */
}

.top-character-container {
  display: flex;
  justify-content: center;
}

.leaderboard-container {
  width: 20%; /* Keep the width of the leaderboard containers */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(180deg, #a7c0f5 0%, #7892d4 100%);
  border-radius: 10px;
  padding: 20px 20px 10px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.left-leaderboard {
  order: 1;
}

.top-character-container {
  order: 2;
}

.right-leaderboard {
  order: 3;
}


@media (max-width: 1200px) {
  .top-character-row {
    flex-direction: column;
    align-items: center;
  }

  .leaderboard-container {
    width: 100%;
    max-width: 645px;
    margin-bottom: 20px;
  }

  .top-character-container {
    width: 100%;
    max-width: 645px;
  }
}

.leaderboard-container h3 {
  margin-bottom: 10px;
  font-size: 1.5em;
  line-height: 1.2;
}

.creatorItem {
  display: flex;
  justify-content: center; /* Center the entire creator item */
  align-items: center;
  width: 100%;
  color: white;
  margin-bottom: 10px;
}

.creatorName {
  flex: none; /* Prevents the name from stretching too much */
  margin-right: 10px; /* Adjusted space between name and score */
  color: #000;
  text-align: center; /* Center-align the text within its container */
}

.creatorScore {
  flex: none; /* Prevents the score from stretching too much */
  font-weight: bold;
  color: #813cdb;
  text-align: right; /* Align the score to the right of its container */
}

.rank {
  font-weight: bold;
  margin-right: 10px;
  color: #096dd9;
}

.discord-gif {
  width: 125px; /* Adjust the width as needed */
  height: auto; /* Maintain the aspect ratio */
  margin-bottom: -5px; /* Adjust spacing */
  margin-top: -25px; /* Adjust spacing */
}

.prizes {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-align: center;
}

.gems-image {
  width: 130px; /* Adjust the width as needed */
  height: auto; /* Maintain the aspect ratio */
  margin-bottom: -20px; /* Add spacing between image and the elements below */
}

.gems-image2 {
  width: 110px; /* Adjust size as needed */
  height: auto;
  margin-bottom: -20px; /* Add spacing between image and the elements below */
}

.gems {
  font-size: 1.2em;
  margin-bottom: -10px;
}

.plus {
  font-size: 3em;
  margin-bottom: -10px;
}

.discord-role {
  font-size: 1em;
  margin-bottom: 5px;
}

.card {
  width: 645px; /* Fixed width for all cards */
  height: 500px; /* Fixed height for all cards (adjust as needed) */
  margin: 0 auto 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  font-family: PPPangramSansRounded-Bold !important;
  background: hsla(0, 0%, 100%, 0.3);
  border-radius: 5px;
  box-shadow: 0 0 30px hsla(0, 0%, 100%, 0.3);
  display: flex;
  flex-direction: column;
}

.card-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
}

.character-image {
  width: 300px;
  height: 300px; /* Fixed height for images */
  object-fit: cover; /* This ensures the image covers the area without distortion */
  border-radius: 24px;
}

.card-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 2rem;
}

.actions-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.net-votes {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0.5rem;
}

.character-user {
  font-size: 0.8em;
  color: gray;
}

.character-card.top-1 {
  border: 2px solid gold;
  background-color: rgba(255, 215, 0, 0.3); /* Semi-transparent gold */
}

.character-card.top-2 {
  border: 2px solid silver;
  background-color: rgba(192, 192, 192, 0.3); /* Semi-transparent silver */
}

.character-card.top-3 {
  border: 2px solid #cd7f32;
  background-color: rgba(205, 127, 50, 0.3); /* Semi-transparent bronze */
}

@media (max-width: 1200px) {
  .top-character-row {
    flex-direction: column;
    align-items: center;
  }

  .leaderboard-container {
    position: static; /* Reset position for smaller screens */
    width: 100%;
    max-width: 645px;
    margin-bottom: 20px;
  }

  .top-character-container,
  .card {
    width: 100%;
    max-width: 645px;
  }
}

@media (max-width: 700px) {
  .card {
    height: auto; /* Allow height to adjust on very small screens */
  }

  .character-image {
    width: 100%;
    height: auto;
  }
}

.headline {
  margin-top: 3rem;
  text-align: center;
  font-size: 2em;
  color: white;
  margin-bottom: 20px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.subheadline {
  text-align: center;
  font-size: 1.2em;
  color: white;
  margin-bottom: 40px;
}

.addCharacterBtn {
  margin-left: 3rem;
}

@media (max-width: 600px) {
  .addCharacterBtn {
    display: block;
    width: 80%;
    text-align: center;
    box-sizing: border-box;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.filterBtmContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.filterButton {
  margin: 0 5px;
  padding: 10px 15px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filterButton.active {
  background-color: rgba(255, 255, 255, 0.3);
}

.filterButton:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

///




@mixin mobile {
  @media screen and (max-width: 480px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: 768px) {
    @content;
  }
}
@mixin laptop {
  @media screen and (max-width: 1200px) {
    .grid {
      grid-template-columns: repeat(4, 1fr);
    }
    @content;
  }
}
.poweredBy{
  align-items: center;
    display: flex;
}
body::-webkit-scrollbar {
  z-index: 1000000;
}
.formContainer {
  background-image: linear-gradient(180deg, #5C75CC 0%, #203378 100%);
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .formWrapper {
    display: flex;
    flex-direction: column;
    max-width: 400px; /* Set a max-width for better control */
    width: 100%; /* Ensure it takes up the available width */
    gap: 10px;
    color: white;
    align-items: center;
    justify-content: center;
    .forgotPassword {
      cursor: pointer;
    }
    .logo {
      font-weight: bold;
      font-size: 24px;
    }

    .title {
      font-size: 12px;
    }

    form {
      font-family: Moderat;
      display: flex;
      flex-direction: column;
      gap: 15px;

      input {
        padding: 10px;
        border: none;
        width: 300px;
        color: black;
        border-bottom: 1px solid #a7bcff;
        &::placeholder {
          color: rgb(175, 175, 175);
        }
      }

      label {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #8da4f1;
        font-size: 12px;
        cursor: pointer;

        img {
          width: 32px;
        }
      }
    }
    span {
      text-align: center;
    }
    p {
      font-size: 12px;
      margin-top: 10px;
    }
  }
}

.ChatWrapper {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  width: auto;
  text-align: center;
  background-image: linear-gradient(180deg, #5C75CC 0%, #203378 100%);
  height: 100vh;
  display: flex;
  background-size: cover;
  align-items: center;
  justify-content: center;
  .kebab-menu-button {
    position: absolute;
    right: 0;
    font-size: 1.3em; // This will make the icon bigger
    border: none;
    background: none;
    color: #000; // Use the color you want
    margin-right: 3.5rem;
    cursor: pointer;
    
    &:hover,
    &:focus {
      color: #555; // Use the color you want for hover and focus
    }
  }
  .container {
    width: 75%; /* Default width for PCs */
    height: 100%;
    display: flex;
    overflow: hidden;

    @include tablet {
      width: 90%;
    }
    /* Mobile responsiveness */
@media only screen and (max-width: 768px) { /* Adjust this breakpoint if needed */
    width: 95%;
}
    .sidebar {
      flex: 1;
      background-color: #3e3c61;
      position: relative;

      .navbar {
        display: flex;
        align-items: center;
        background-color: #2f2d52;
        height: 50px;
        padding: 10px;
        justify-content: space-between;
        color: #ddddf7;

        .logo {
          font-weight: bold;
          @include tablet {
            display: none;
          }
        }

        .user {
          display: flex;
          gap: 10px;

          img {
            background-color: #ddddf7;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            object-fit: cover;
          }

          button {
            background-color: #5d5b8d;
            color: #ddddf7;
            font-size: 10px;
            border: none;
            cursor: pointer;
            @include tablet {
              position: absolute;
              bottom: 10px;
            }
          }
        }
      }
      .search {
        border-bottom: 1px solid gray;

        .searchForm {
          padding: 10px;

          input {
            background-color: transparent;
            border: none;
            color: white;
            outline: none;

            &::placeholder {
              color: lightgray;
            }
          }
        }
      }

      .userChat {
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: white;
        cursor: pointer;

        &:hover {
          background-color: #2f2d52;
        }

        img {
          width: 50px; 
          height: 50px;
          border-radius: 50%;
          object-fit: cover;
        }

        .userChatInfo {
          span {
            font-size: 18px;
            font-weight: 500;
          }
          p {
            font-size: 14px;
            color: lightgray;
          }
        }
      }
    }
    .chat {
      flex: 2;
      height: 90%;
      align-self: center;
      align-items: center;
      justify-content: center;
      width: 100%;
      .button-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .chatInfo {
        .photo {
          width: 14%;
          max-width: 60px;
          border-radius: 15px;
          display: flex;
          z-index: 10;
          object-fit: cover;
          overflow: hidden;
          cursor: pointer;
        }
        
        .photo:hover {
          transform: scale(1.05);
        }
        
        
        transition: opacity 0.3s ease-out 0s;
        position: relative;
        border-radius: 15px 15px 15px 15px;
        height: 10px;
        display: flex;
        align-items: center;
        padding: 30px;
        gap: 5px;
        color: rgb(0, 0, 0);
        font-family: Moderat;
        justify-content: center;
        .shop {
        }
        .options{
          display: flex;
          gap: 5px;
        }
        .back {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto 0;
          margin-left: 0.5rem;
          font-family: PPPangramSansRounded-Bold;
          max-height: 90%;
          cursor: pointer;
          font-size: 1.5rem;
          color: white;
        }
        span{
          color: white;
          font-family: PPPangramSansRounded-Bold;
        }
      }

      .chatIcons {
        display: flex;
        gap: 10px;

        img {
          height: 24px;
          cursor: pointer;
        }
      }
      .messages::-webkit-scrollbar {
        display: none;
      }
      
      .messages {
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        font-family: Moderat;
        font-size: 1.25rem;
        padding: 10px;
        height: calc(100% - 200px);
        overflow-y: scroll;
        /* Style for mobile devices (e.g., iPhone SE, iPhone XR) */
@media screen and (max-width: 767px) {
      height: calc(100% - 200px); /* Adjusted height for mobile devices */
}
        .date-divider {
          display: flex;
          justify-content: center;
          margin: 20px 0;
        }
        .topic-divider {
          display: flex;
          justify-content: center;
          margin: 20px 0;
        }
        
        .date-text {
          background-color: #e1f3fb; /* Light blue background */
          color: #525252; /* Dark text color */
          font-size: 12px;
          padding: 5px 10px;
          border-radius: 10px;
          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
        }
        .topic-text {
          background-color: #92deff; /* Light blue background */
          color: #525252; /* Dark text color */
          font-size: 12px;
          padding: 5px 10px;
          border-radius: 10px;
          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
        }
        
        .full-screen {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 9999;
        }
        
        .full-screen img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        
        .image-resize-icon {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 24px;
          cursor: pointer;
          color: white;
        }
        textarea.editInput {
          font-size: 80%;
          align-items: flex-start;
          background-color: #ffffff;
          color: #000;
          padding: 10px 20px;
          border-radius: 20px 20px 8px 20px;
          max-width: max-content;
          border: none;
          outline: none;
          resize: none;
          overflow: hidden; /* Removes scrollbar */
          min-height: 20px;
          white-space: pre-wrap; /* Allows line breaks */
          width: 100%;
          box-sizing: border-box;
        }
        
        &.owner textarea.editInput {
          align-self: flex-end;
          background-color: #fcfff7;
          color: rgb(0, 0, 0);
        }
        .message {
          display: flex;
          gap: 20px;
          margin-bottom: 20px;
          .video {
            max-width: 200px; /* Optimal width for visibility */
            max-height: 400px; /* Sufficient height for detail */
            border-radius: 10px; /* Rounded corners for a modern look */
            overflow: hidden; /* Hides anything outside the border radius */
            background: #000; /* Black background for contrast */
            position: relative; /* Keeps the video within the chat flow */
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow for depth */
            border: 1px solid #ddd; /* Light border for definition */
            transition: transform 0.3s ease; /* Smooth transition for hover effect */
          }
          
          .video video {
            width: 100%; /* Full width of the container */
            height: auto; /* Maintain aspect ratio */
            display: block; /* Block level for proper spacing */
            max-height: 100%; /* Respects container's height */
          }
          
          /* Responsive design for smaller screens */
          @media (max-width: 600px) {
            .video {
              max-width: 170px; /* Optimal width for visibility */
              max-height: 300px; /* Adjusted height for smaller screens */
            }
          }
          .blurred {
            filter: blur(10px);
            pointer-events: none;
          }
          .displayText {
            font-family: PPPangramSansRounded-Bold;
            cursor: pointer;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .messageInfo {
            display: flex;
            color: gray;
            font-size: 50%;
            font-weight: 300;

            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
          .messageContent {
            max-width: 80%;
            display: flex;
            flex-direction: column;

            p {
              font-size: 80%;
              align-items: flex-start;
              background-color: #ffffff;
              color: #000;
              padding: 10px 20px;
              border-radius: 20px 20px 8px 20px;
              max-width: max-content;
            }
            .noselect {
              -webkit-touch-callout: none; /* iOS Safari */
              -webkit-user-select: none; /* Safari */
              -khtml-user-select: none; /* Konqueror HTML */
              -moz-user-select: none; /* Old versions of Firefox */
              -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                              supported by Chrome, Edge, Opera and Firefox */
            }
            .unlockedImg {
              pointer-events: none;
              width: 50%;
              filter: blur(10px);
            }
            .image {
              position: relative;
              width: 90%; /* default width for very small devices */
            }
            
            .image img {
              width: 100%; /* takes the full width of .image */
            }
            
            /* Small devices (landscape phones, up to 576px) */
            @media (min-width: 576px) {
              .image {
                width: 80%; /* width for small devices */
              }
            }
            
            /* Medium devices (tablets, up to 768px) */
            @media (min-width: 768px) {
              .image {
                width: 70%; /* width for medium devices */
              }
            }
            
            /* Large devices (desktops, up to 992px) */
            @media (min-width: 992px) {
              .image {
                width: 60%; /* width for large devices */
              }
            }
            
            /* Extra large devices (large desktops, 1200px and up) */
            @media (min-width: 1200px) {
              .image {
                width: 50%; /* width for extra large devices */
              }
            }

            .text {
              font-family: PPPangramSansRounded-Bold;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 1;
              color: white;
              img {
                padding-left: 4px;
                pointer-events: none;
                width: 25px;
                filter: none;
              }
            }
          }

          &.owner {
            flex-direction: row-reverse;

            .messageContent {
              text-align: left;
              align-items: flex-end;
              p {
                align-self: flex-end;
                background-color: #fcfff7;
                color: rgb(0, 0, 0);
              }
            }
          }
        }
      }


    }
  }
}
.input {
  backdrop-filter: blur(25px);
  background: rgba(0, 0, 0, 0.15);
  padding: 5px 20px;
  border-radius: 20px 20px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Moderat;

input {
  width: 90%;
  border: none;
  outline: none;
  color: white;
  font-size: 18px;
  font-family: Moderat;
  transition: font-size 0.3s ease-in-out; /* Adjusting to transition font-size */
  background: none;

  &::placeholder {
    opacity: 0.8;
    font-family: PPPangramSansRounded-Bold;
    color: rgb(255, 255, 255);
    font-size: 16px;
    line-height: 18px;
    max-height: 150px;
    padding: 18px 10px 18px 0px;
  }

  &:focus {
    opacity: 1; /* Slightly larger font-size when focused */
  }
}
  .buttons {
    display: flex;
    align-items: center;
    button {
      border: none;
      cursor: pointer;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      width: 34px;
      height: 34px;
      color: rgb(94, 94, 94);
      background: linear-gradient(rgb(255, 255, 255) 0%, rgb(217, 217, 217) 100%);
      box-shadow: rgba(209, 209, 209, 0.9) 0px 0px 30px, rgba(255, 255, 255, 0.8) 0px 0px 5px inset;
      border-radius: 14px;
      opacity: 1;
      padding: 0px;
      -webkit-transition: box-shadow 0.3s ease, opacity 0.3s ease;
      transition: box-shadow 0.3s ease, opacity 0.3s ease;
      margin-left: 0.5rem;
    }
    button:hover {
      opacity: 0.7;
    }
  }
  .send {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      height: 24px;
      cursor: pointer;
    }

    .undoIcon{
      margin-left: 0.2rem;
      margin-top: 0.2rem;
    }
  }
}

.typingWrapper {
  background-color: rgb(255, 255, 255);
  width: 80px;
  height: 40px;
  border-radius: 20px 20px 8px 20px;
  margin-bottom: 2rem;
}
.typingLoader {
  top: 17px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  -webkit-animation: typing 1s linear infinite alternate;
  -moz-animation: Typing 1s linear infinite alternate;
  animation: typing 1s linear infinite alternate;
  margin: 46px auto; /* Not necessary- its only for layouting*/
  position: relative;
  left: -12px;
}
@-webkit-keyframes typing {
  0% {
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }
  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }
  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 1);
  }
}

@-moz-keyframes typing {
  0% {
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }
  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }
  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 1);
  }
}

@keyframes typing {
  0% {
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }
  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }
  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 1);
  }
}

/*Typing Loader*/
.typing_loader {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  -webkit-animation: typing 1s linear infinite alternate;
  -moz-animation: Typing 1s linear infinite alternate;
  animation: typing 1s linear infinite alternate;
  left: -12px;
}
@-webkit-keyframes typing {
  0% {
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }
  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }
  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 1);
  }
}

@-moz-keyframes typing {
  0% {
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }
  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }
  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 1);
  }
}

@keyframes typing {
  0% {
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }
  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }
  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 1);
  }
}

.mainWrapper{
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f2f5;
    padding: 20px;
  }
  
  .loading-message {
    text-align: center;
    margin-top: 20px;
    max-width: 400px;
  }
  
  .loading-message h3 {
    font-size: 28px;
    color: #1890ff;
    margin-bottom: 10px;
    animation: pulse 2s infinite;
  }
  
  .loading-message p {
    font-size: 18px;
    color: #4a4a4a;
    margin-bottom: 20px;
  }
  
  .loading-progress {
    width: 100%;
    height: 6px;
    background-color: #e8e8e8;
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .loading-bar {
    width: 0;
    height: 100%;
    background-color: #1890ff;
    animation: loading 5s linear infinite;
  }
  
  .loading-facts {
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;
    border-radius: 5px;
    padding: 10px;
    margin-top: 20px;
  }
  
  .loading-facts p {
    font-size: 16px;
    color: #096dd9;
    margin: 0;
  }
  
  @keyframes loading {
    0% { width: 0; }
    100% { width: 100%; }
  }
  
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  }
  background-size: cover;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto; /* enable vertical scrolling */
  background: linear-gradient(180deg, #5C75CC 0%, #203378 100%);
}
.contentWrapper{
  margin-bottom: 10rem;
  width: 100%;
}
.selectChat {
  
  .seperator{
    height: 2px;
margin-top: 3rem;
background: rgba(255, 255, 255, 0.1);
  }
  .wrapper{
    display: flex;
  }
  .banner {
    background-color: #7289da;
    color: white;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 20px;
    margin-bottom: 2rem;
  }
  
  .banner-icon {
    margin-right: 15px;
    font-size: 30px;
  }
  
  .banner-link {
    color: white;
    text-decoration: none;
    font-size: 25px;
  }
  
  .banner-link:hover {
    text-decoration: underline;
  }
  
  .button{
    font-size: 15px;
    text-align: center;
    padding: 17px 27px;
margin: 0.3rem;
  }
  .signUpPopup{
    text-align: center;
    color: white;
    margin-top: 1rem;
    margin-bottom: 4rem;
    .logo{
      z-index: 1;
      margin-left: 0.3rem;
    width: 160px;
    }
  }
  /* Align Vertically */
  /* --- ---------- ----- */
  .content {
    margin-bottom: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    h1 {
      color: white;
      margin-bottom: 1rem;
    }
    @media (min-width: 300px) {
      .grid {
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
      }
    }
    @media (min-width: 600px) {
      .grid {
        width: 100%;
        grid-template-columns: repeat(3, 1fr);
      }
    }
    @media (min-width: 900px) {
      .grid {
        width: 80%;
        grid-template-columns: repeat(3, 1fr);
      }
    }


    .favorites-container {
      display: flex;
      flex-direction: column;
      align-items: center; /* Centers items horizontally */
      justify-content: center; /* Centers items vertically */
      margin-top: 20px; /* Space from the FilterBar */
    }
    
      .NoWaifus{
        margin-top: 60px;
        color: white;
        font-size: 50px;
        text-align: center;
      }
      
      .favorites-info {
        margin-top: 30px; /* Changed from margin-top to margin-bottom */
        padding: 10px;
        background-color: #f0f8ff; /* Light blue background */
        border-left: 4px solid #007bff; /* Blue border for emphasis */
        color: #333;
        font-size: 20px;
        border-radius: 4px;
        text-align: center;
        max-width: 600px;
        height: 400px;
      }

      .favorites-video {
        margin-left: 160px;
        margin-top: 5px; /* Space between the text and the video */
        width: 100%; /* Full width of the container */
        max-width: 250px; /* Optional: Max width to prevent it from being too large */
      }

      .favorites-video video {
        border-radius: 8px; /* Rounded corners for the video */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
      }

      @media (max-width: 768px) {
        .favorites-container {
          margin-top: 15px; /* Slightly reduced top margin on tablets */
        }
      
        .NoWaifus {
          font-size: 16px; /* Slightly smaller font on tablets */
        }
      
        .favorites-info {
          font-size: 13px; /* Slightly smaller font on tablets */
          padding: 8px;
          max-width: 400px;
        }
      
        .favorites-video {
          margin-left: 65px;
          margin-top: 5px; /* Space between the text and the video */
          width: 100%; /* Full width of the container */
          max-width: 250px; /* Optional: Max width to prevent it from being too large */
        }
      }

      @media (max-width: 480px) {
        .favorites-container {
          margin-top: 10px; /* Further reduced top margin on mobile */
        }
      
        .NoWaifus {
          font-size: 14px; /* Smaller font on mobile */
        }
      
        .favorites-info {
          font-size: 13px; /* Slightly smaller font on tablets */
          padding: 8px;
          max-width: 320px;
          height: 320px;
        }
      
        .favorites-video {
          margin-left: 50px;
          margin-top: 5px; /* Space between the text and the video */
          width: 100%; /* Full width of the container */
          max-width: 200px; /* Optional: Max width to prevent it from being too large */
        }
      
        .favorites-video video {
          border-radius: 6px; /* Slightly smaller rounded corners on mobile */
        }
      }

      .grid {
        margin-top: 1rem;
        max-width: 1500px;
        display: grid;
        gap: 3px;
      }
    }

  }
.head {
  display: flex;
  flex-direction: row;
  .backButton {
    margin-left: 0.8rem;
  }
}
.landingWrapper {
  position: relative;
  height: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  color: white;
  text-align: center;
  position: relative;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .margin {
    margin: 0.2rem;
  }
  .socials{
    z-index: 1000;
    display: flex;
    gap: 10px;
  }
  .socials a i {
    font-size: 24px; /* increase the font size */
    height: 24px; /* increase the height */
    color: white;
  }
  
.overlay{
position: absolute;
width: 100%;
height: 100%;
left: 0px;
right: 0;
top: 0px;

background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(32, 51, 120, 0.5) 50%, #5C75CC 100%);

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
z-index: 1;
}
  .landingContainer {
    margin-top: 0px;
    color: white;
    text-align: center;
    height: 200vh;
    height: auto;
    .content {
      .infos{
        justify-content: center;

        background: linear-gradient(180deg, #5C75CC 0%, #203378 100%);
        z-index: 2;
        .faq{
          display: flex;
          flex-direction: column;
          width: 90%;
          margin-left: auto;
          margin-right: auto;
          height: auto;
          .header{
            font-weight: 400;
font-size: 52px;
line-height: 58px;
/* identical to box height, or 112% */

display: flex;
justify-content: center;
margin-bottom: 3rem;

          }
          ul{
            @media (min-width: 300px) {
              flex-direction: column;
            }
            @media (min-width: 600px) {
              flex-direction: row;
            }
            @media (min-width: 900px) {
              flex-direction: row;
            }
padding-left: 0rem;
width: 100%;
height: 100%;
margin-bottom: 3rem;
display: flex;
gap: 20px;
justify-content: center;
margin-left: auto;
margin-right: auto;
li{
  text-align: left;
.header-li{
  text-align: left;
  width: 285px;
height: 22px;
  font-weight: 400;
font-size: 20px;
line-height: 22px;
/* identical to box height, or 110% */

display: flex;
align-items: center;

color: #FFFFFF;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}
.answer{
  text-align: left;
  font-family: moderat;
  margin-top: 0.1rem;
height: 100%;
  font-weight: 400;
font-size: 15px;
line-height: 24px;
/* or 160% */

display: flex;

color: #FFFFFF;
}
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 32px 47px 153px 30px;

width: 362px;
height: 272px;
left: 392px;
top: 300px;

background: rgba(255, 255, 255, 0.1);
border-radius: 24px;
}
          }
        }
      }
      .startScreen {

        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        h1{
          z-index: 1;
        }
        .poweredBy{
          z-index: 1;
          text-align: center;
          margin-top: 0.5rem;
          .span{
            z-index: 1;
            font-size: 20px;
          }
          display: flex;
            .logo{
              z-index: 1;
              margin-left: 0.3rem;
            width: 50px;
            height: 50px
            }
          }
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        background-size: cover;
        background-position: center;
        height: 100vh;
        width: 100%;
      }
      overflow-x: hidden;
      overflow-y: hidden;
      margin-bottom: auto;
      position: relative;
      height: 200vh;
      height: auto;
      display: flex;
      align-self: center;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;

      .text {
        font-size: 300%;
        align-self: center;
      }
    }
  }
}
@font-face {
  font-family: "PPPangramSansRounded-Bold";
  src: local("PPPangramSansRounded-Bold"),
    url("../fonts/PPPangramSansRounded-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Moderat";
  src: local("Moderat"), url("../fonts/Moderat-Regular.ttf") format("truetype");
}
h1,
h2,
h3,
h4 {
  font-family: PPPangramSansRounded-Bold;
  font-weight: 400;
} /*!sc*/
body {
  overflow-y: hidden;
  font-family: PPPangramSansRounded-Bold !important;
  white-space: preline;
  
}
.button {
  z-index: 1;
  cursor: pointer;
  border: none;
  border-radius: 24px;
  align-self: center;
  font-family: PPPangramSansRounded-Bold, moderat;
  line-height: 20px;
  font-size: 20px;
  text-align: center;
  padding: 20px 30px;
  background: linear-gradient(180deg, rgba(224, 224, 224, 0) 0%, #d9d9d9 100%),
    rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.9),
    inset 0px 0px 5px rgba(255, 255, 255, 0.8);
  color: #5e5e5e;
  opacity: 1;
  -webkit-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
}
.button:hover {
  opacity: 1;
  box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.9),
    inset 0px 0px 5px rgba(255, 255, 255, 0.8);
} /*!sc*/
.loader-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-image: url(/img/spinner.gif), linear-gradient(180deg, #5C75CC 0%, #203378 100%);
  background-size: 100px 100px, auto !important;
  background-position: center, center;
  background-repeat: no-repeat, no-repeat;
}
.signInButton {
  border: none;
  border-radius: 24px;
  align-self: center;
  font-family: PPPangramSansRounded-Bold, moderat;
  line-height: 20px;
  font-size: 20px;
  text-align: center;
  padding: 10px 15px;
  background: linear-gradient(180deg, rgba(224, 224, 224, 0) 0%, #d9d9d9 100%),
    rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.9),
    inset 0px 0px 5px rgba(255, 255, 255, 0.8);
  color: #5e5e5e;
  opacity: 1;
  -webkit-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  img{
    width: 30px;
  }
}
.signInButton:hover {
  opacity: 1;
  box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.9),
    inset 0px 0px 5px rgba(255, 255, 255, 0.8);
} /*!sc*/
.sidebarGem {
  background-color: rgba(255, 255, 255, 0.946);
  text-align: center;
  h1 {
    font-family: PPPangramSansRounded-Bold;
  }
  font-family: moderat;
  .gemGrid {
    display: grid;
    overflow-x: auto;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 163px);
    margin-left: auto;
    margin-right: auto;
    gap: 20px;
    .gemItem {
      background-image: url("../img/multipleGems.png");
      background-position: center;
      background-size: 90px 60px;
      background-repeat: no-repeat;
      background-color: rgb(255, 255, 255);
      border-radius: 24px;
      width: 130px;
      height: 130px;
      .price {
        text-align: center;
        margin-top: 4.7rem;
      }
      .gems {
        text-align: center;
      }
    }
  }
}

.gemCounter {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  align-self: flex-end;
  font-family: moderat !important;
  img {
    padding-left: 4px;
    margin-bottom: 0.1rem;
    width: 20px;
  }
}
.imagineWrapper {
  height: 100vh;
  background-color: #12162c;
  .head {
    display: flex;
    flex-direction: row;
    height: 7%;
    .backButton {
      position: relative;
      margin-top: 0.2rem;
      margin-left: 0.8rem;
      align-self: flex-end;
    }
  }
  .promptModule {
    border-style: outset;
    border-left: none;
    border-right: none;
    border-color: #363862;
    height: 100vh;
    width: 100%;
    background: #12162c;
    .generate {
      @media (min-width: 300px) {
        display: flex;
        flex-direction: column;
      }
      @media (min-width: 600px) {
        display: flex;
        flex-direction: row;
        textarea {
          width: 100%;
        }
      }
      @media (min-width: 900px) {
        display: flex;
        flex-direction: row;
        textarea {
          width: 100%;
        }
      }
      .button {
        img {
          padding-left: 4px;
          max-width: 20px;
          max-height: 20px;
        }
        cursor: pointer;
        margin-top: 0.8rem;
        margin-left: 0.5rem;
        margin-right: 1rem;
        border: none;
        border-radius: 24px;
        align-self: center;
        font-family: PPPangramSansRounded-Bold, moderat;
        line-height: 20px;
        font-size: 16px;
        text-align: center;
        padding: 20px 30px;
        background: linear-gradient(
            180deg,
            rgba(224, 224, 224, 0) 0%,
            #d9d9d9 100%
          ),
          rgba(255, 255, 255, 0.9);
        box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.9),
          inset 0px 0px 5px rgba(255, 255, 255, 0.8);
        color: #5e5e5e;
        opacity: 1;
        -webkit-transition: box-shadow 0.3s ease;
        transition: box-shadow 0.3s ease;
        max-width: 180px;
        max-height: 80px;
      }
      .button:hover {
        opacity: 1;
        box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.9),
          inset 0px 0px 5px rgba(255, 255, 255, 0.8);
      }

      textarea {
        outline: none;
        margin-top: 1.25rem;
        max-height: 80px;
        margin-left: 1rem;
        margin-right: 1rem;
        background: #0b0c23;
        border: 0;
        color: #ffffff;
        font-family: "moderat";
        font-size: 0.875rem;
        padding: 10px 0 10px 10px;
        resize: none;
        touch-action: pan-y;
        height: 150%;
      }
    }
    .imageDisplay {
      display: flex;
      flex-direction: column;
      .image-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      margin-top: 1.25rem;
      justify-content: center;
      display: flex;
      .downloadButton {
        margin-top: 1rem;
        cursor: pointer;
        border: none;
        border-radius: 24px;
        align-self: center;
        font-family: PPPangramSansRounded-Bold, moderat;
        line-height: 20px;
        font-size: 20px;
        text-align: center;
        padding: 15px 25px;
        background: linear-gradient(
            180deg,
            rgba(224, 224, 224, 0) 0%,
            #d9d9d9 100%
          ),
          rgba(255, 255, 255, 0.9);
        box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.9),
          inset 0px 0px 5px rgba(255, 255, 255, 0.8);
        color: #5e5e5e;
        opacity: 1;
        -webkit-transition: box-shadow 0.3s ease;
        transition: box-shadow 0.3s ease;
      }
      .downloadButton:hover {
        opacity: 1;
        box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.9),
          inset 0px 0px 5px rgba(255, 255, 255, 0.8);
      } /*!sc*/
      .disableSelect {
        pointer-events: none;
      }
      img {
        margin-left: auto;
        margin-right: auto;
        @media (min-width: 300px) {
          width: 80%;
          height: 30%;
        }
        @media (min-width: 600px) {
          width: 80%;
          height: 30%;
        }
        @media (min-width: 900px) {
          width: 40%;
          height: 30%;
        }
      }
    }
    #visiblePart, #blurredPart {
      display: inline; /* Ensure the text is in one line */
      position: relative;
    }
    
    .blurred {
      user-select: none;
      filter: blur(14px);
    }
    
    .blurred::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      background: linear-gradient(to right, transparent, white 50%);
    }
    .loader-container {
      width: 100%;
      height: 80%;
      background-image: url(/img/spinner.gif);
      background-size: 100px 100px !important;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .sidebar {
    color: white;
    background-color: #363862;
    height: 100vh;
    min-width: 330px;
    width: 20%;
    display: flex;
    justify-content: center;
    .settingsContent {
      margin-top: 1rem;
      overflow: scroll;
      .imageResolutionPart {
        margin-top: 1rem;
        background-color: #0e0f21;
        box-shadow: none;
        width: 225px;
        height: 200px;
      }
      .tagsPart {
        margin-top: 1rem;
        background-color: #0e0f21;
        box-shadow: none;
        width: 225px;
        height: 500px;
      }
      .dropdown {
        margin-top: 1rem;
        width: 200px;
      }
    }
  }
}
.navLink {
  cursor: pointer;
}
.navbar {
  text-align: center;
  .mobile-menu {
    color: white;
    background-position: center;
    background-repeat: no-repeat;
  }
}
.settings {
  color: #5e5e5e;
  text-align: left;
  img {
    width: 40px;
    margin: 1rem;
  }
  .settingsButton {
    cursor: pointer;
  }
}
.gemCounter {
  align-self: flex-end;
  font-family: PPPangramSansRounded-Bold, moderat;
  img {
    padding-left: 4px;
    width: 20px;
  }
}
.abos {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  .subtitle
  {
    font-size: 20px;
  }
  .card-body{
    margin-top: 1rem;
  }
  .banner{
    z-index: 3;
    position: absolute;
    top: -1px;
    left: 0px;
    right: 0px;
    margin: 0px auto;
    min-width: 70px;
    max-width: fit-content;
    height: 24px;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    background-image: linear-gradient(92deg, rgba(214, 165, 253, 0.9) 0%, rgba(137, 99, 245, 0.9) 72%);
    border-radius: 3px 3px 20px 20px;
    .bannerText{
      margin-top: 0.2rem;
      padding: 2px 15px 0px;
    font-size: 18px;
    line-height: 14px;
    font-family: PPPangramSansRounded-Bold, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
    white-space: nowrap;
    color: rgb(255, 255, 255);
    }
  }
  .saleGems{
    text-align: center;
    z-index: 3;
    position: absolute;
    width: 55px;
    height: 55px;
    border: none;
    top: 30px;
    right: 15px;
    display: flex;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background: radial-gradient(135.43% 1312% at -27.21% -40%, rgb(255, 221, 134) 0%, rgb(231, 110, 154) 100%);
    box-shadow: rgb(0, 0, 0) 0px 0px 3px inset, rgb(255, 255, 255) 0px 0px 13.5px inset;
    border-radius: 92px;
    transform: rotate(15deg);
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.01em;
    text-align: center;
    color: rgb(255, 255, 255);
    cursor: default;
    user-select: none;
  }
  .sale{
    z-index: 3;
    position: absolute;
    width: 60px;
    height: 60px;
    border: none;
    top: 30px;
    right: 15px;
    display: flex;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background: radial-gradient(135.43% 1312% at -27.21% -40%, rgb(255, 221, 134) 0%, rgb(231, 110, 154) 100%);
    box-shadow: rgb(0, 0, 0) 0px 0px 3px inset, rgb(255, 255, 255) 0px 0px 13.5px inset;
    border-radius: 92px;
    transform: rotate(15deg);
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.01em;
    text-align: center;
    color: rgb(255, 255, 255);
    cursor: default;
    user-select: none;
  }
  background: linear-gradient(rgb(255, 162, 109) -9%, rgb(131, 113, 242) 45%, rgb(53, 37, 98) 103%), linear-gradient(rgb(92, 116, 204) 0%, rgb(33, 52, 122) 100%);
  height: 100vh;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  .nav {
    text-align: center;
  }
  .wrapper
  {
  }
  
  .content {
    overflow-x: none;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    .shopImg {
      align-self: center;
      width: 100%;
    }
    .title {
      margin-top: 4rem;
      text-align: center;
      margin-bottom: 2rem;
      h1 {
        font-size: 42px;
      }
      span {
        font-size: 24px;
      }
    }
    .gemGrid {
      margin-right: auto;
      margin-left: auto;
      color: #000;
      display: grid;
      align-items: center;
      justify-content: center;
      max-width: 1000px;
      @media (min-width: 300px) {
          grid-template-columns: repeat(2, 1fr);
      }
      @media (min-width: 600px) {
          grid-template-columns: repeat(3, 1fr);
      }
      @media (min-width: 900px) {
          grid-template-columns: repeat(5, 1fr);
      }
      gap: 20px;
      margin-bottom: 2rem;
      .gemItem {
        display: flex;
        font-family: PPPangramSansRounded-Bold, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        width: 163px;
        border-radius: 19px;
        position: relative;
        background: radial-gradient(140.83% 100% at 50.32% 100%, rgba(7, 201, 66, 0) 42.5%, rgba(7, 201, 66, 0.7) 100%), rgba(255, 255, 255, 0.1);
        .priceButton {
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          padding: 0px;
          gap: 5px;
          margin: 10px 0px;
          width: 143px;
          height: 34px;
          background: linear-gradient(rgb(255, 255, 255) 0%, rgb(217, 217, 217) 100%);
          box-shadow: rgba(209, 209, 209, 0.9) 0px 0px 15px, rgba(255, 255, 255, 0.4) 0px 0px 5px inset;
          border-radius: 14px;
          border: none;
          cursor: pointer;
          font-size: 16px;
          line-height: 34px;
          color: rgb(94, 94, 94);
      
        }
        .gems {
          font-weight: 400;
          font-size: 20px;
          line-height: 22px;
          color: rgb(255, 255, 255);
          font-family: PPPangramSansRounded-Bold, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
        }
        img {
          margin-top: 2rem;
          margin-bottom: 1rem;
          width: 120px;
          height: 80px;
          filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 5px 30px);
        }
      }
    }
    .pricing {
      text-align: left;
      color: black;
      .pricing .card {
        border: none;
        border-radius: 1rem;
        transition: all 0.2s;
        box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
      }

      .pricing hr {

      }

      .pricing .card-title {
        margin: 0.5rem 0;
        font-size: 0.9rem;
        letter-spacing: 0.1rem;
        font-weight: bold;
      }

      .pricing .card-price {
        font-size: 3rem;
        margin: 0;
      }

      .pricing .card-price .period {
        font-size: 0.8rem;
      }

      .pricing ul li {
        margin-bottom: 1rem;
      }
      ul {
        list-style-type: none;
      }

      li.check::before {
        content: "✓ " !important;
        color:  #4e73df !important;
      }
      li.text-muted::before {
        content: "✘ " !important;
        color: red !important;
      }

      .pricing .text-muted {
        opacity: 0.7;
      }

      .pricing .btn {
        cursor: pointer;
        font-size: 80%;
        border-radius: 5rem;
        letter-spacing: 0.1rem;
        font-weight: bold;
        padding: 1rem;
        opacity: 0.7;
        transition: all 0.2s;
      }

      /* Hover Effects on Card */

      @media (min-width: 992px) {
        .pricing .card:hover {
          margin-top: -0.25rem;
          margin-bottom: 0.25rem;
          box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
        }

        .pricing .card:hover .btn {
          opacity: 1;
        }
      }
    }
  }
}

.checkoutWrapper {
  margin: 2rem;
  .feedbackForm {
    background: rgba(255, 255, 255, 0.05); /* Slight background */
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.feedbackForm textarea {
    width: 100%;
    min-height: 100px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3); /* Lighter border */
    font-size: 16px;
    background: rgba(255, 255, 255, 0.08); /* Lightened background */
    color: #fff;
    resize: vertical; /* Allow vertical resizing */
    transition: all 0.3s ease-in-out;
}

.feedbackForm textarea::placeholder {
    color: rgba(255, 255, 255, 0.6); /* Lighter placeholder text */
}

.feedbackForm textarea:focus {
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0.12); /* Slightly darker background on focus */
    outline: none;
}

.feedbackForm button {
  margin-top: 1rem;
  z-index: 1;
  cursor: pointer;
  border: none;
  border-radius: 24px;
  align-self: center;
  font-family: PPPangramSansRounded-Bold, moderat;
  line-height: 20px;
  font-size: 16px; /* Adjusted font size for a feedback submit button */
  text-align: center;
  padding: 12px 20px; /* Adjusted padding for the feedback submit button */
  background: linear-gradient(180deg, rgba(224, 224, 224, 0) 0%, #d9d9d9 100%), rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.9), inset 0px 0px 5px rgba(255, 255, 255, 0.8);
  color: #5e5e5e;
  opacity: 1;
  -webkit-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
}

.feedbackForm button:hover {
  opacity: 1;
  box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.9), inset 0px 0px 5px rgba(255, 255, 255, 0.8);
}

.showFeedback {
  z-index: 1;
  cursor: pointer;
  border: none;
  border-radius: 24px;
  font-family: PPPangramSansRounded-Bold, moderat;
  line-height: 20px;
  font-size: 16px;
  text-align: center;
  padding: 12px 20px;
  background: linear-gradient(180deg, rgba(224, 224, 224, 0) 0%, #d9d9d9 100%), rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.9), inset 0px 0px 5px rgba(255, 255, 255, 0.8);
  color: #5e5e5e;
  opacity: 1;
  -webkit-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  margin-top: 15px;  /* Space between the feedback form and the button */
}

.showFeedback:hover {
  opacity: 1;
  box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.9), inset 0px 0px 5px rgba(255, 255, 255, 0.8);
}
  
  
  .desc {
    text-align: center;
    margin: auto;
    flex-direction: column;
    display: flex;
    justify-content: center;
  }
  .title {
    font-size: 12px;
    color: #89CFF0; /* Light blue color for the title to make it serene */
}

.price {
    color: #FFFFFF; /* Keeping it white for clarity, but slightly bold for emphasis */
    font-size: 30px;
    font-weight: bold;
}

  .wrapper {
    overflow-y: auto;
    max-width: 554px;
    margin: 10px auto;
    border-radius: 7px 7px 7px 7px;
    /* VIA CSS MATIC https://goo.gl/cIbnS */
    -webkit-box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, 0.15);
  }
}
.ratings {
  height: auto;
  width: 100%;
  margin-bottom: 8rem;
  margin-top: 4rem;

  h5{
width: 80%;
  }
  h1{
    font-weight: 400;
    font-size: 52px;
    line-height: 58px;
    /* identical to box height, or 112% */
    
    display: flex;
    justify-content: center;
    margin-bottom: 0.2rem;
  }
  .home-testimonial {
    height: auto;
  }
  .home-testimonial-bottom {
    margin-top: 20px;
    margin-bottom: 0px;
    position: relative;
    height: auto;
    top: 190px;
  }
  .home-testimonial h3 {
    color: var(--orange);
    font-size: 14px;
    font-weight: 500;
  }
  .home-testimonial h2 {
    color: white;
    font-size: 28px;
    font-weight: 700;
  }
  .testimonial-inner {
        font-family: moderat;
    position: relative;
    top: -174px;
  }
  .testimonial-pos {
    font-family: PPPangramSansRounded-Bold;
    position: relative;
    top: 24px;
  }
  .testimonial-inner .tour-desc {
    background: rgba(255, 255, 255, 0.3);
box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.3);
border-radius: 24px;
    font-family: moderat;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    margin: 1rem;
    border-radius: 5px;
    padding: 40px;
  }
  .color-grey-3 {
    font-size: 14px;
    font-family: moderat;
    color: white;
  }
  .testimonial-inner img.tm-people {
    width: 60px;
    height: 60px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    max-width: none;
  }
  .link-name {
    font-family: moderat;
    font-size: 14px;
    color: white;
  }
  .link-position {
    font-family: moderat;
    font-size: 12px;
    color: white;
  }
}
// Footer.scss
.footer {
  text-align: center;
  color: white;
  padding: 20px 0;

  .container {
    max-width: 1200px;
    margin: auto;
    padding: 0 15px;
  }

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .text-justify {
    text-align: justify;
  }

  a {
    color: #fff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  h4{
    text-align: center;
  }
  @media (max-width: 768px) {
    h4{
      font-size: 18px;
      text-align: center;
    }
  }
  .links-row {
    max-width: 400px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: auto; // Center the links row

  }

  .impressum-link {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .impressum-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    
    .modal-content {
      background: white;
      color: #5e5e5e;
      padding: 20px;
      border-radius: 5px;
      position: relative;
      max-width: 500px;
      width: 100%;
  
      .close {
        position: absolute;
        top: 10px;
        right: 15px;
        cursor: pointer;
        font-size: 20px;
      }
  
      a {
        color: #5e5e5e;
      }
  
      @media (max-width: 768px) {
        width: 90%;
        max-width: none;
      }
    }
  }
}


.faq {
  text-align: left;
margin-bottom: 5rem;
 .faq-header{
  margin-top: 3rem;
  font-size: 42px;
  border-bottom: 1px dotted #ccc;
  padding: 24px;
}

.faq-content {
  margin: 0 auto;
}

.faq-question {
  padding: 20px 0;
  border-bottom: 1px dotted #ccc;
}

.panel-title {
  font-size: 24px;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 10px 10px 0 48px;
  display: block;
  cursor: pointer;
}

.panel-content {
  color: white;
  font-size: 20px;
  padding: 0px 14px;
  margin: 0 40px;
  height: 0;
  overflow: hidden;
  z-index: 1;
  position: relative;
  opacity: 0;
  -webkit-transition: .4s ease;
  -moz-transition: .4s ease;
  -o-transition: .4s ease;
  transition: .4s ease;
}

.panel:checked ~ .panel-content{
  height: auto;
  opacity: 1;
  padding: 14px;
}

.plus {
  position: absolute;
  margin-left: 20px;
  margin-top: 4px;
  z-index: 8;
  font-size: 42px;
  line-height: 100%;
  -webkit-user-select: none;    
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: .2s ease;
  -moz-transition: .2s ease;
  -o-transition: .2s ease;
  transition: .2s ease;
}

.panel:checked ~ .plus {
  z-index: 8;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.panel {
  display: none;
}
}
.scroll-indicator {
  background: #333;
  height: 100vh;
  position: relative;
  width: 100%;
}

.scroll-indicator::before {
  animation: bounce 1s ease infinite;
  bottom: 2rem;
  color: #fff;
  content: '╲╱';
  font-size: 2rem;
  height: 4rem;
  left: 50%;
  letter-spacing: -1px;
  line-height: 4rem;
  margin-left: -3rem;
  opacity: 0.8;
  position: absolute;
  text-align: center;
  width: 6rem;
}

@keyframes bounce {
  50% {
    transform: translateY(-50%);
  }
}
.simpleContent{
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  background-image: linear-gradient(rgb(92, 117, 204) 0%, rgb(32, 51, 120) 100%);
  display: flex;
    flex-direction: column;
  .content{
    color: white;
    margin-top: 6rem;
    margin-bottom: 2rem;
    height: auto;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    flex-direction: column;
    justify-content: center;
  }
}
.accept-terms
{
  color: white;
  font-size: small;
  font-family: moderat;
  text-align: center;
}
.backButton{
  background: transparent;
  border: none;
}
.createWaifu{

  color: white;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #5C75CC 0%, #203378 100%);
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  form{
    margin-bottom: 6rem;
    overflow-x: hidden;
    padding: 30px;
  }
  h1{
    font-size: 17px;
    font-weight: 650;
    margin-top: 3rem;
  }
  span{
    margin-left: 0.5rem;
  }
  .content{
    @media (min-width: 768px) {
      margin-left: 4rem;
      margin-right: 4rem;
    }
    margin: auto;
    overflow-y: scroll;
    overflow-x: hidden;
    .bookAnker
    {
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: white;
    }
.section{
  margin-bottom: 2rem;
}
label{
  width: 100%;
}
input[type="file"]{
  font-family: moderat;
  
}
.info{
  font-family: moderat;
  font-size: small;
}
input[type="text"]{
  font-family: moderat;
  -webkit-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #212529;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  width: 100%;
}
  }
}

.loaderModal {
  display: flex;
  position: fixed;
  z-index: 7000;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.8); 

  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #5C75CC 0%, #203378 100%);
    margin: auto;
    border: 1px solid #888;
    width: 300px;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.25);
    text-align: center;

    h2 {
      margin-bottom: 10px;
      color: #fff;
      font-size: 20px;
    }

    p {
      color: #fff;
      font-size: 16px;

      a {
        color: #fff;
        text-decoration: underline;

        &:hover {
          color: #ddd;
        }
      }
    }
    
  }
}
.spin{
	background-color: purple;
	width: 50px;
	height: 50px;
	border-radius: 50%;
  margin: auto;
	position: relative;
	background: conic-gradient(#3dffc9, #f7c723, #eb0ea5, #3dffc9);
	animation: 2s spin linear infinite;
	 box-shadow:  0px 0px 150px -75px black;
}

.spin::before{
	content: '';
	position: absolute;
	background-color:white;
	width: 80%;
	height: 80%;
	border-radius: 50%;
	top: 50%;
	left: 50%;
	 box-shadow: inset 0px 0px 110px -70px black;
	 border: 4px solid white;
	transform: translate(-50%,-50%);

}

@keyframes spin{
	100%{
		transform: rotate(360deg);
	}
}




.feedbackForm{
width: 100%;
display: flex;
flex-direction: column;
.inputs{
  display: flex;
align-items: center;
justify-content: center;
width: 100%;
textarea {
  font-family: moderat;
  margin-right: 15px;
  padding: 10px;
  resize: vertical;
  max-height: 300px;
  background-color: #fff;
  border-radius: 2px;
  border: 1px rgba(14, 17, 22, 0.2) solid;
  font-size: 14px;
}
  .submitFeedbackBtn{
    width: 100px;
    height: 34px;
    background: linear-gradient(rgb(255, 255, 255) 0%, rgb(217, 217, 217) 100%);
    box-shadow: rgba(209, 209, 209, 0.9) 0px 0px 15px, rgba(255, 255, 255, 0.4) 0px 0px 5px inset;
    border-radius: 14px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: rgb(94, 94, 94);
  }
}
}
.feedback{
  margin-bottom: 2rem;
  button{
    margin: 1rem;
  }
}

.verifyPopup{
  margin-bottom: 3rem;
  padding: 10px;
  background-image: linear-gradient(92deg, rgba(214, 165, 253, 1) 0%, rgba(137, 99, 245, 1) 72%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  color: white;
  border-radius: 5px;
  z-index: 999;
  .buttons{
    display: flex;
  }
  button {
    img {
      padding-left: 4px;
      max-width: 20px;
      max-height: 20px;
    }
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    margin-top: 0.8rem;
    margin-left: 0.5rem;
    margin-right: 1rem;
    border: none;
    border-radius: 24px;
    align-self: center;
    font-family: PPPangramSansRounded-Bold, moderat;
    line-height: 20px;
    font-size: 14px;
    text-align: center;
    padding: 20px 30px;
    background: linear-gradient(
        180deg,
        rgba(224, 224, 224, 0) 0%,
        #d9d9d9 100%
      ),
      rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.9),
      inset 0px 0px 5px rgba(255, 255, 255, 0.8);
    color: #5e5e5e;
    opacity: 1;
    -webkit-transition: box-shadow 0.3s ease;
    transition: box-shadow 0.3s ease;
    max-width: 130px;
    max-height: 80px;
  }
  button:hover {
    opacity: 1;
    box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.9),
      inset 0px 0px 5px rgba(255, 255, 255, 0.8);
  }
}
.changeEmailPopup
{
  input{
font-family: moderat;
  }
  span{
    color: white
  }
  button{
    margin-top: 1rem;
    margin-right: 1rem;
  }
  color: white;
  background-image: linear-gradient(92deg, rgba(214, 165, 253, 1) 0%, rgba(137, 99, 245, 1) 72%);
}
.subscription-window{
  /* Default style that applies on smaller devices */
img {
  width: 60%;
}

/* Media query for larger screens - typically desktops */
@media (min-width: 1024px) { /* Adjust the min-width as needed for your breakpoint */
  img {
    width: 100%;
  }
}
  h1{
    margin-top: 1rem;
    color: white;
    text-align: center;
  }
  h2{
    color: white;
  }
  .col-md-6{
    flex-direction: column;
    display: flex;
    align-items: center;
  }
  img{
    margin-bottom: 1rem;
  }
  span{
    color: white;
  }
  ul{
    color: white;
  }
  background: linear-gradient(180deg, #5C75CC 0%, #203378 100%);
  img{
    border-radius: 10px;
  }
  .subscriptions{
    display: flex;
    justify-content: center;
  }
  .learnMore{
    text-align: center;
    margin-top: 2rem;
    font-size: 20px;
    color: white;
    a{
      color: white;
    }
  }
  h4{
    font-size: 18px;
  }
  .subscriptionItem {
    margin-inline: 0.3rem;
    display: flex;
    font-family: PPPangramSansRounded-Bold, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 283px;
    max-height: 340px;
    border-radius: 19px;
    position: relative;
    background: radial-gradient(140.83% 100% at 50.32% 100%, rgba(7, 201, 66, 0) 42.5%, rgba(7, 201, 66, 0.7) 100%), rgba(255, 255, 255, 0.1);
    .priceButton {
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      padding: 0px;
      gap: 5px;
      margin: 10px 0px;
      width: 103px;
      height: 34px;
      background: linear-gradient(rgb(255, 255, 255) 0%, rgb(217, 217, 217) 100%);
      box-shadow: rgba(209, 209, 209, 0.9) 0px 0px 15px, rgba(255, 255, 255, 0.4) 0px 0px 5px inset;
      border-radius: 14px;
      border: none;
      cursor: pointer;
      font-size: 16px;
      line-height: 34px;
      color: rgb(94, 94, 94);
  
    }
    .banner{
      z-index: 3;
      position: absolute;
      top: -1px;
      left: 0px;
      right: 0px;
      margin: 0px auto;
      min-width: 70px;
      max-width: fit-content;
      height: 24px;
      display: flex;
      flex-direction: column;
      -webkit-box-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      align-items: center;
      background-image: linear-gradient(92deg, rgba(214, 165, 253, 0.9) 0%, rgba(137, 99, 245, 0.9) 72%);
      border-radius: 3px 3px 20px 20px;
      .bannerText{
        margin-top: 0.2rem;
        padding: 2px 8px 0px;
      font-size: 18px;
      line-height: 14px;
      font-family: PPPangramSansRounded-Bold, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
      white-space: nowrap;
      color: rgb(255, 255, 255);
      }
    }
    .gems {
      font-weight: 400;
      font-size: 20px;
      line-height: 22px;
      color: rgb(255, 255, 255);
      font-family: PPPangramSansRounded-Bold, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
    }
    img {
      margin-top: 2rem;
      margin-bottom: 1rem;
      width: 120px;
      height: 80px;
      filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 5px 30px);
    }
    .price{
      color: white;
      font-family: moderat;
      font-weight: bold;
    }
    .product{
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  }
}
/* SettingsPage.css */

.page-container {
  width: 90%;
  max-width: 800px;
  margin: auto;
  padding-top: 50px;
}

.profile-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 50px;
}

.profile-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}

.user-name {
  font-size: 24px;
  color: #333333;
  margin: 10px 0;
}

.user-email {
  font-size: 16px;
  color: #777777;
  margin-bottom: 20px;
}

.edit-button {
  background: #007BFF;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
}

.section-title {
  font-size: 18px;
  color: #333333;
  margin-bottom: 20px;
}

.settings-button {
  background: #ffffff;
  border: 1px solid #007BFF;
  color: #007BFF;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  margin-bottom: 10px;
  display: block;
  text-align: left;
  width: 100%;
  text-decoration: none;
}

.copyright{
  color: rgba(34, 34, 34, 0.7);
    font-size: 13px;
    padding: 10px 20px 0px;
}
.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-cancel {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #fff;
}

.btn-cancel:hover {
  background-color: #5a6268;
  border-color: #545b62;
}
.deleteAccount{
  color: red;
  cursor: pointer;
}
.deleteAccount:hover{
  text-decoration: underline;
}
.chatButton {
  margin-inline: 0.1rem;
  height: 45px;
  background-color: rgb(232, 232, 232);
  background-size: 30px 30px, 18px 20px;
  background-position: 85% 50%, 20% 50%;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  font-family: moderat;
  padding: 5px 22px;
  border-radius: 20px 20px 20px 20px;
  border: none;
  cursor: pointer;
  border: 2px solid rgb(232, 232, 232);
  img {
    margin-bottom: 0.15rem;
    margin-right: 0.2rem;
    height: 20px;
  }
}
.chatButton:hover {
  border: 2px solid #000000;
}
.multistep{
  /* General styles */

h1 {
  font-weight: 500;
  color: #343a40;
}

/* Multi-step form styles */
max-width: 800px;

.card {
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-body {
  padding: 30px;
}

.card-title {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 15px;
}

.form-control {
  border-radius: 5px;
}

.form-check-label {
  font-size: 1rem;
  font-weight: 400;
}

.btn {
  font-weight: 500;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: background-color 0.2s ease;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  background-color: #5a6268;
  border-color: #5a6268;
}

.float-right {
  margin-left: 10px;
}

}
.burger-menu{
  border-radius: 14px;
  border: none;
  width: 40px;
  height: 40px;
  color: white;
  margin-top: 0.1rem;
  background: rgba(0, 0, 0, 0.08);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main{
  display: flex;
  height: 100vh;
  width: 100vh;
}
.continueChatting{
  height: 100vh;
  width: 100%;
  background: linear-gradient(180deg, #5C75CC 0%, #203378 100%);
  
}
.chat-container {
  height: 80vh; // Set a fixed height
  margin-left: 6rem;
  font-family: PPPangramSansRounded-Bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem;
  box-sizing: border-box;
  overflow-y: auto; // Add scrollbar when needed
}

.chat-heading {
  font-family: PPPangramSansRounded-Bold;
  color: white;
}

.chat-name {
  color: white;
  font-family: moderat;
}

.chat-last-message {
  color: white;
  font-family: moderat;
}

.chat-item {
  background-color: transparent;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
}
.dropdown-item.selected {
  background-color: #0D6EFD;
  color: white;
}

.dropdown-item:hover {
  background-color: #007bff;
  color: white;
}
.nav-icon {
  font-size: 18px; /* decrease the size to make icons smaller */
  margin-right: 10px;
}

.nav-link {
  display: flex;
  align-items: center;
  color: white; /* ensure this is the same as .nav-icon color */
  text-decoration: none;
}

.nav-link:hover {
  color: #ddd; /* choose the color you want for the hover effect */
}
.gemCounter{
  justify-content: center;
  align-items: center;
  display: flex;
}
@media (max-width: 599px) {
  .navCollapse {
    color: white;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 600px) {
  .navCollapse {
    color: white;
    display: block;
  }
}
.advancedAI{
  border-radius: 24px;
  align-self: center;
  display: 'flex';
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.08);
  height: 30px;
  width: 80px;
  margin-left: 0.5rem;
  img{
    width: 24px;
  }
}
#mainNavbar.mainNav{
  position: sticky;
  top: 0; 
  z-index: 20;
}
.addCharacterBtn{
  margin-left: 3rem;
}
.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #7289da;
  border-radius: 10px;
  padding: 20px;
  margin: 20px auto;
  color: white;
  font-size: 20px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.countdown > div {
  margin: 0 20px;
  text-align: center;
}

.countdown > div span {
  display: block;
  font-size: 30px;
  font-weight: bold;
  color: #ffffff;
}

.countdown > div label {
  display: block;
  font-size: 20px;
  color: #ffffff;
}

@media (max-width: 600px) {
  .countdown {
    padding: 10px;
  }
  .countdown > div {
    margin: 0 10px;
  }
  .countdown > div span {
    font-size: 20px;
  }
  .countdown > div label {
    font-size: 12px;
  }
}
.headline {
  margin-top: 3rem;
  text-align: center;
  font-size: 2em;
  color: white;
  margin-bottom: 20px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.subheadline {
  text-align: center;
  font-size: 1.2em;
  color: white;
  margin-bottom: 40px;
}
@media (max-width: 600px) {
  .addCharacterBtn {
    display: block;
    width: 80%;
    text-align: center;
    box-sizing: border-box;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
  }
}
.card {
  max-width: 645px;
  margin: auto;
  margin-top: 2rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth effect */
  font-family: PPPangramSansRounded-Bold !important;
  background: hsla(0,0%,100%,.3);
  border-radius: 5px;
  box-shadow: 0 0 30px hsla(0,0%,100%,.3);
}

.card-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.character-image {
  border-radius: 24px;
  width: 300px;
  height: auto;
}


.card-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 2rem;
}

.actions-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.net-votes {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0.5rem;
}

.character-user {
  font-size: 0.8em;
  color: gray;
}

.character-card.top-1 {
  border: 2px solid gold;
  background-color: #FFD700;
}

.character-card.top-2 {
  border: 2px solid silver;
}

.character-card.top-3 {
  border: 2px solid #cd7f32;
}


.ChatMenuWindow {
  backdrop-filter: blur(50px);
  background: rgba(0, 0, 0, 0.13);
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1200;
  padding: 0 20px;  /* added padding for better spacing on small screens */
  
  .close-button {

    border: none;
    width: 54px;
    height: 54px;
    border-radius: 24px;
    color: white;
    background: rgba(0, 0, 0, 0.08);
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 20px;  /* added margin to separate from other elements */
  }
  
  a {
    text-align: center;
    color: white;
    text-decoration: none; /* Remove underline */
  }
  
  a:hover {
    color: white;
  }
  
  button {
    border: none;
    background: none;
    color: white;
  }
  
  ul {
    width: 100%;
    max-width: 600px;
    padding: 20px;  /* reduced padding */
    list-style: none;
    display: flex;
    flex-direction: column;  /* changed to single column layout */
    gap: 20px;  /* reduced gap */
    align-items: center;  /* center align items */
  }
  @media (max-width: 768px) {
    .close-button {
      position: absolute;
      top: 10px;  /* moved to top on mobile... */
      right: 10px;  /* ...and slightly in from the edge */
      transform: none;  /* remove vertical centering */
    }
  }
  @media (min-width: 600px) {
    ul {
      flex-direction: row;  /* revert to grid layout on larger screens */
      flex-wrap: wrap;  /* allow items to wrap onto next line */
      justify-content: space-between;  /* space items evenly */
    }
  }
}
.fullscreen-image-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5); /* Optional: semi-transparent background */
  z-index: 9999;
}

.fullscreen-image-container img {
  max-width: 90%; /* Adjust as needed */
  max-height: 90%; /* Adjust as needed */
  object-fit: contain;
}

.fullscreen-image-container .image-resize-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: white;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.pagination button {
  padding: 10px 20px;
  border: none;
  background-color: #1890ff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination span {
  font-size: 18px;
}
.buy-anonymously-section {
  background: linear-gradient(135deg, rgba(255, 162, 109, 0.1), rgba(131, 113, 242, 0.1));
  padding: 15px 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin: 20px auto;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 90%; // starts with 90% width for mobile
  text-align: center;

  @media (min-width: 768px) {
    max-width: 70%; // adjust for tablet
  }

  @media (min-width: 1024px) {
    max-width: 50%; // adjust for desktop
  }

  h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px; // starts with 20px font size for mobile
    margin-bottom: 5px;
    color: #fff;
    font-family: PPPangramSansRounded-Bold, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;

    @media (min-width: 768px) {
      font-size: 24px; // adjust for tablet
    }
  }

  p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px; // starts with 14px font size for mobile
    margin: 0;
    font-family: PPPangramSansRounded-Bold, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;

    @media (min-width: 768px) {
      font-size: 16px; // adjust for tablet
    }
  }

  i.fas.fa-user-secret {
    margin-right: 10px;
    font-size: 25px; // starts with 25px icon size for mobile
    color: #fff;
    filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.2));

    @media (min-width: 768px) {
      font-size: 30px; // adjust for tablet
    }
  }
}
.nav-icon-lock {
  margin-left: 5px;
}
.disabled-link {
  pointer-events: none;
  cursor: default;
}
.new-badge {
  position: absolute;
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  background-color: rgba(77, 145, 255, 0.9);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 0.8rem;
}
.newBadge {
  background-color: rgba(77, 145, 255, 0.9);
  color: white;
  padding: 3px 6px;
  margin-left: 8px;
  border-radius: 50%;
  font-size: 12px;
  vertical-align: middle;
}
.textgem {
  width: 20px;
  height: 20px;
  padding-left: 3px;
  margin-bottom: 0.15rem
}

.upload-container {
  border-radius: 24px;
  width: 300px;
  height: auto;
  text-align: center;
}

.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.locked{
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.lockedGem{
  margin-bottom: 0.2rem;
    padding-right: 3px;
    width: 20px;
}
/* Add this in your main CSS file if you need customizations beyond Tailwind's scope */
.favorite-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: 3px solid #606c88; // Dark grey-blue for a modern, neutral look
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s ease, border-color 0.3s, color 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Soft shadow for depth

  .fa-star {
    color: #606c88; // Matching the border color for a cohesive look
    font-size: 25px;
  }

  &:hover {
    border-color: #ef476f; // Bright fuchsia for a striking hover effect
    .fa-star {
      color: #ef476f;
    }
    transform: rotate(10deg); // Dynamic interaction
  }

  &:active {
    transform: scale(0.9) rotate(-10deg); // Click effect
  }

  &.is-favorite {
    border-color: #ffd166; // Warm yellow for a noticeable favorited state
    .fa-star {
      color: #ffd166;
    }
  }
}

.input-button {
  border: none;
  cursor: pointer;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 34px;
  height: 34px;
  color: rgb(94, 94, 94);
  background: linear-gradient(rgb(255, 255, 255) 0%, rgb(217, 217, 217) 100%);
  box-shadow: rgba(209, 209, 209, 0.9) 0px 0px 20px, rgba(255, 255, 255, 0.8) 0px 0px 5px inset;
  border-radius: 14px;
  opacity: 1;
  padding: 0px;
  -webkit-transition: box-shadow 0.3s ease, opacity 0.3s ease;
  transition: box-shadow 0.3s ease, opacity 0.3s ease;
}
.input-button:hover {
  opacity: 0.7;
}
.waifu-notification .ant-notification-notice {
  background-color: #4a76a8; // Example background color
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  // Add other styles such as padding, margins, etc., to match your UI design
}
.video-options-container {
  position: relative;
  display: flex;
  flex-direction:row;
  .menu-popup {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-70%) translateY(-10px);
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 24px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    z-index: 100;
    min-width: 200px;
    max-height: 400px; // Set a max height to prevent it from being too tall
    overflow-y: auto; // Allows scrolling if content exceeds max height
    transition: transform 0.3s ease;

    &.show {
      visibility: visible;
      opacity: 1;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li div {
        display: block;
        width: 100%;
        padding: 10px 15px;
        text-align: left;
        background-color: transparent;
        cursor: pointer;
        font-size: 0.9rem;
        transition: background-color 0.3s;

        &:hover, &:focus {
          background-color: #f8f8f8;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .video-options-container {

    .menu-popup {
      position: absolute;      
      left: 0;
      bottom: 0;
      transform: translateX(-50%) translateY(-40px);
      width: 20px; // Full width for mobile
      border-radius: 24px; // Adjusted for mobile for a full-width bottom-aligned menu
      box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.2);
      max-height: 50vh; // Takes up to 50% of the screen height
      overflow-y: auto; // Scroll if content is taller than max height
      font-size: 0.9rem; // Adjust font size as needed for mobile readability
    }
  }
}
.disclaimer{
  margin-top: 20px;
  text-align: center;
  color: white;
}

.security-icons {
  display: flex;
  justify-content: center;
  gap: 100px;
  text-align: left;
  color: #d5d3da;;
}

.anti-virus, .bank-statement {
  display: flex;
  align-items: center;
}

.anti-virus-icon, .bank-statement-icon {
  margin-right: 5px;
  filter: brightness(100) saturate(100%) invert(90%) sepia(10%) saturate(0%) hue-rotate(270deg) brightness(93%) contrast(88%);
}

.bank-statement-icon {
  height: auto;
  width: 60px;
}

.anti-virus-icon {
  height: auto;
  width: 45px;
}

.text-wrapper {
  display: flex;
  flex-direction: column;
}

.anti-virus p, .bank-statement p {
  margin: 0;
}

.anti-virus .secured {
  margin-top: -8px;
}

@media (max-width: 500px) {
  .anti-virus {
    font-size: 15px;
  }
  .bank-statement {
    font-size: 15px;
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
  }

.security-icons {
  display: flex;
  justify-content: center;
  gap: 30px;
  text-align: left;
  color: #d5d3da;;
}
.bank-statement-icon {
  height: auto;
  width: 45px;
}

.anti-virus-icon {
  height: auto;
  width: 33px;
}
}

@media (max-width: 350px) {
  .anti-virus {
    font-size: 12px;
  }
  .bank-statement {
    font-size: 12px;
  }
  .security-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    text-align: left;
    color: #d5d3da;;
  }
  .bank-statement-icon {
    height: auto;
    width: 40px;
  }
  
  .anti-virus-icon {
    height: auto;
    width: 30px;
  }
}

.bank-statement .statement {
  margin-top: -8px;
}



/* General Styles */
.purchase.ultra {
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: scale(1.03);
  }
  position: relative; /* For any absolute positioning inside if needed */

  /* Define a gradient background with multiple colors */
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);

  /* Ensure the background is large enough to allow smooth animation */
  background-size: 400% 400%;

  /* Apply the gradient animation */
  animation: gradientAnimation 15s ease infinite;

  border-radius: 10px;
  overflow: hidden;

  /* Use relative units for better responsiveness */
  width: 100%;
  max-width: 1200px; /* Maintain a maximum width for large screens */
  height: 250px;

  margin: 0 auto 30px auto; /* Center the container and add bottom margin */

  display: flex;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  align-items: center;
  justify-content: space-between; /* Distribute space between items */

  padding: 0 20px; /* Add horizontal padding */

  box-sizing: border-box; /* Include padding and border in the element's total width and height */

  /* Add a subtle box-shadow for depth */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.purchase-text {
  color: transparent;
}
.purchase-txt {
  top: 30px;
  color: transparent;
  position: relative;
  z-index: 1;
}

.purchase-txt .inner-wrapper {
  color: white;
  height: 60px;
  background-color: #d47382;
  border-radius: 8px;
  position: absolute; /* Remove from normal flow for precise positioning */
  padding: 5px 10px;
  width: 100px;
  position: absolute; /* Remove from normal flow for precise positioning */
  top: 50%;            /* Position vertically at 50% of the container */
  left: 50%;           /* Position horizontally at 50% of the container */
  transform: translate(-50%, -50%) translateY(10px); /* Center and offset by 10px vertically */
}

.purchase-txt .inner-wrapper .off {
  color: rgb(66, 78, 87);
}

.waifus-txt {
  font-size: 50px;
  top: 70px;
  left: 325px;
  color: transparent;
  position: relative;
  z-index: 1;
}

.waifus-txt .waifus-wrapper {
  word-spacing: -2px; /* Fine-tune overall spacing */
  color: white;
  height: 60px;
  border-radius: 8px;
  position: absolute; /* Remove from normal flow for precise positioning */
  padding: 50px 10px;
  width: 330px;
  position: absolute; /* Remove from normal flow for precise positioning */
  top: 50%;            /* Position vertically at 50% of the container */
  left: 50%;           /* Position horizontally at 50% of the container */
  transform: translate(-50%, -50%) translateY(10px); /* Center and offset by 10px vertically */
  line-height: 1;
}

.waifus-txt .waifus-wrapper .woff {
  color: rgb(66, 78, 87);
  margin-left: -5px; /* Slight adjustment */
}




/* Image Classes */
.rias {
  width: 250px; /* Set your desired width */
  height: auto;  /* Maintains aspect ratio */
  margin-bottom: 50px;
  margin-top: 170px;
  margin-left: 15px;
  
  /* Optional: Add responsive adjustments */
  max-width: 100%;
  height: auto;
}

.nezuko {
  width: 315px; 
  height: auto; 
  position: relative; /* Changed from absolute to relative */
  margin-left: -30px;   
  top: -13px;             
  left: 220px;        
  
  max-width: 100%;
}

.kushina {
  width: 270px; 
  height: auto;
  margin-bottom: 50px;
  margin-top: 170px;
  margin-left: -400px;
  
  max-width: 100%;
  height: auto;
}

/* Keyframes for the gradient animation */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  33% {
    background-position: 50% 100%;
  }
  66% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Media Queries */

/* Media Query for max-width: 1200px */
@media (max-width: 1200px) {
  .purchase.ultra {
    max-width: 900px;
    padding: 20px; /* Add padding for better spacing */
    justify-content: center; /* Center items horizontally */
  }

  .waifus-txt {
    left: 255px;
  }

  .purchase-txt {
    left: 30px;
    top: 200px;
  }

  .nezuko {
    position: relative;
  }
  

  .kushina {
    width: 270px; 
    height: auto; 
    margin: 50px 0 0 100px; /* Adjusted margins for better positioning */
    
    max-width: 100%;
  }

  .rias {
    display: none;
  }
}

@media (max-width: 1000px) {
  .purchase.ultra {
    max-width: 800px;
    padding: 20px; /* Add padding for better spacing */
    justify-content: center; /* Center items horizontally */
  }

  .nezuko {
    position: relative;
  }
  

  .kushina {
    width: 270px; 
    height: auto; 
    margin: 50px 0 0 100px; /* Adjusted margins for better positioning */
    
    max-width: 100%;
  }

  .rias {
    display: none;
  }
}

@media (max-width: 800px) {
  .purchase.ultra {
    max-width: 700px;
    padding: 20px; /* Add padding for better spacing */
    justify-content: center; /* Center items horizontally */
  }

  .nezuko {
    position: relative;
  }
  .waifus-txt {
    left: 255px;
    font-size: 30px;
  }
  .waifus-txt .waifus-wrapper .woff {
    margin-left: 3px; /* Slight adjustment */
  }  
  .purchase-txt .inner-wrapper {
    left: -150px;
    top: -80px;
  }
  .kushina {
    width: 270px; 
    height: auto; 
    margin: 50px 0 0 100px; /* Adjusted margins for better positioning */
    
    max-width: 100%;
  }

  .rias {
    display: none;
  }
}

/* Additional Media Queries (Ensure they come after general styles and earlier media queries) */

@media (max-width: 900px) {
  .nezuko {
    width: 215px; 
    height: auto; 
    position: relative; /* Ensure it's within flex flow */
    margin: 0 10px 0 0; /* Simplified margins */
    
    max-width: 50%;
  }
}

@media (max-width: 700px) {
  .purchase.ultra {
    width: 500px;
  }
  .waifus-txt {
    top: 90px;
    left: 165px;
    font-size: 30px;
  }
  .waifus-txt .waifus-wrapper .woff {
    margin-left: 3px; /* Slight adjustment */
  }  
  .purchase-txt .inner-wrapper {
    left: -70px;
    top: -10px;
  }
  .character-image {
    max-width: 100%;
  }
  .nezuko {
    display: none;
  }
  .kushina {
    display: none;
  }
  .rias {
    display: flex;
    margin-right: 230px;
  }
}

@media (max-width: 500px) {
  .purchase.ultra {
    width: 400px;
    height: 140px;
    justify-content: center; /* Center items horizontally */
    padding: 10px; /* Reduce padding for smaller screens */
  }
  .waifus-txt {
    top: 95px;
    left: 55px;
    font-size: 25px;
  }

  .waifus-txt .waifus-wrapper .woff {
    color: #c2ce24;
  }

  .nezuko {
    display: none;
  }

  .kushina {
    display: none;
  }

  .rias {
    display: block; /* Ensure it's displayed */
    margin-right: 125px; /* Adjust margin for better spacing */
    width: 40px; /* Use a reasonable fixed width */
    height: auto;
  }
  .purchase-txt .inner-wrapper {
    left: 100px;
    top: -150px;
  }
}


@media (max-width: 400px) {
  .purchase.ultra {
    width: 370px;
    justify-content: center; /* Center items horizontally */
    padding: 10px; /* Reduce padding for smaller screens */
    margin-top: -18px;
    margin-bottom: 15px;
  }
  .waifus-txt {
    top: 85px;
    left: 27px;
    font-size: 25px;
  }

  .nezuko {
    display: none;
  }

  .kushina {
    display: none;
  }

  .rias {
    display: block; /* Ensure it's displayed */
    margin-right: 125px; 
    width: 50px; /* Use a reasonable fixed width */
    height: auto;
  }

  .purchase-txt .inner-wrapper {
    left: 90px;
    top: -150px;
  }
}

@media (max-width: 360px) {
  .purchase.ultra {
    width: 340px;
  }
  .waifus-txt {
    top: 86px;
    left: 35px;
    font-size: 22px;
  }
  .purchase-txt .inner-wrapper {
    left: 85px;
    top: -150px;
  }
  .waifus-txt .waifus-wrapper .woff {
    color:#18ffff;
  }
}

@media (max-width: 340px) {
  .purchase.ultra {
    width: 330px;
  }
}

@media (max-width: 330px) {
  .purchase.ultra {
    width: 280px;
  }
  .purchase-txt .inner-wrapper {
    left: 45px;
    top: -150px;
  }
}

/* Add this to your CSS file */

.rias {
  width: 250px; /* Set your desired width */
  height: auto;  /* Maintains aspect ratio */
  margin-bottom: 50px;
  margin-top: -25px;
  margin-left: 15px;
  
  /* Optional: Add responsive adjustments */
  max-width: 100%;
  height: auto;
}

.nezuko {
  width: 315px; 
  height: auto; 
  position: absolute;
  margin-left: -30px;   
  top: -13px;             
  left: 220px;        
  
  max-width: 100%;
}

.kushina {
  width: 270px; 
  height: auto; 
  margin-bottom: 50px;
  margin-top: -25px;
  margin-left: -400px;
  
  max-width: 100%;
  height: auto;
}