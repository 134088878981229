.diary-overlay {
    position: fixed;
    inset: 0;
    z-index: 9999;
    background: linear-gradient(135deg, rgba(219, 234, 254, 0.7), rgba(229, 231, 235, 0.7));
    backdrop-filter: blur(8px);
  
    &.open {
      .chat {
        .chatInfo {
          .back,
          .burger-menu {
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
          }
        }
      }
    }
  
    &.closed {
      pointer-events: none;
    }
  }
  
  .diary-container {
    position: relative;
    height: 100%;
    max-width: 48rem;
    margin: 0 auto;
    padding: 1.5rem;
    overflow-y: auto;
  }