.get-voice-memo-button {
    margin-top: 1rem;
    padding: 12px 25px; // Slightly reduced padding for a more compact look
    font-size: 0.9em; // Slightly smaller font size for better mobile compatibility
    color: #ffffff;
    background-image: linear-gradient(135deg, #7b4397, #dc2430);
    border: none;
    border-radius: 8px; // Smaller border-radius for a more subtle look
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    outline: none;
    position: relative;
    overflow: hidden;
    z-index: 1;
  
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.2), transparent);
      transition: all 0.65s ease;
      z-index: -1;
    }
  
    &:hover:before {
      left: 100%;
    }
  
    &:active {
      transform: translateY(2px);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); // Slightly lighter shadow for a more refined look
    }
  
    &:focus:not(:active) {
      box-shadow: 0 0 0 3px rgba(123, 67, 151, 0.5); // Thinner focus outline for a cleaner look
    }
  
    // Responsive adjustments for smaller screens
    @media (max-width: 768px) {
      padding: 10px 20px; // Even more reduced padding for small screens
      font-size: 0.85em; // Slightly smaller font size for small screens
      border-radius: 7px; // Even smaller border-radius for a refined look
    }
  
    @media (max-width: 480px) {
      padding: 8px 18px; // Minimum padding for very small screens
      font-size: 0.8em; // Minimum font size for readability
    }
  }
  