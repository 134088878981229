.createWaifuPersonality {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 100vh;
  overflow-y: auto;
  background-image: linear-gradient(180deg, #5C75CC 0%, #203378 100%);
  color: #FFF;
  font-family: 'Moderat', sans-serif; /* Change font-family as per actual font */
  /* If you want the back button to be inside the header */
  .header {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  /* Rest of your CSS */
  
  h1 {
    margin-top: 3rem;
    text-align: center;
  }
  .introduction, .inputArea, .continue-button-wrapper {
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.3);
    margin: 0 10px;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 100%;
  }
  .introduction{
    margin-bottom: 3rem;
  }
  .inputArea {
    margin-bottom: 2rem;
  }
  
  .inputField {
    background-color: rgba(255, 255, 255, 0.6); /* Adjust opacity for brightness */
    border: 2px solid rgba(255, 255, 255, 0.6); /* Light border with adjusted opacity */
    color: #FFF; /* White text color */
    padding: 15px; /* Increased padding for spacious feel */
    margin-bottom: 1rem;
    border-radius: 8px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    width: 90%; /* Increased width */


    &:focus {
      outline: none;
      background-color: rgba(255, 255, 255, 0.7); /* Slightly more opaque on focus */
      border-color: rgba(255, 255, 255, 0.8); /* Subtler white border */
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Soft white glow */
    }
  }


  .inputLabel {
    font-weight: bold;
    display: block;
    margin-top: 20px;
    margin-bottom: 5px;
  }
  
  .continue-button-wrapper {
    margin-top: 3rem;
    margin-bottom: 1rem;
    height: 100px;
    width: 240px;
    border-radius: 10px;
    background: hsla(0,0%,100%,.3);
    box-shadow: 0 0 30px hsla(0,0%,100%,.3);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .continue-button {
    font-family: PPPangramSansRounded-Bold;
    height: 60px;
    width: 200px;
    text-align: center;
    background: linear-gradient(60deg, #4a90e2, #8e44ad, #e74c3c, #e67e22, #f1c40f, #2ecc71, #1abc9c, #34495e);
    background-size: 300% 300%;
    background-color: #2980b9; /* Fallback color */
    border: none;
    border-radius: 8px;
    color: white;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    z-index: 10;
    }
    
    .continue-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
    }
    
    @media (min-width: 768px) {
    .header {
    font-size: 3em;
    }
    
    .inputField {
    /* Adjusting the height for larger screens */
    height: auto;
    }
    }
    
    /* Additional styling for scrollbar */
    .createWaifuPersonality::-webkit-scrollbar {
    width: 10px;
    }
    
    .createWaifuPersonality::-webkit-scrollbar-track {
    background: #f1f1f1;
    }
    
    .createWaifuPersonality::-webkit-scrollbar-thumb {
    background: #888;
    }
    
    .createWaifuPersonality::-webkit-scrollbar-thumb:hover {
    background: #555;
    }
    
}
/* Smaller field for character name */
.createWaifuPersonality .inputField--small {
  height: 35px;
}

/* Medium field for tagline */
.createWaifuPersonality .inputField--medium {
  height: 35px;
}

/* Larger textarea for description */
.createWaifuPersonality .inputField--large {
  height: 80px;
}

/* Extra-large textarea for greeting */
.createWaifuPersonality .inputField--xlarge {
  height: 280px;
}
.createWaifuPersonality .inputField::placeholder {
  color: rgba(255, 255, 255, 0.7); /* Light placeholder color */
  opacity: 1; /* Ensure the placeholder is fully visible */
}

/* For browsers that don't support the ::placeholder selector */
.createWaifuPersonality .inputField:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.createWaifuPersonality .inputField::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
/* Style adjustments for larger screens */
@media (min-width: 768px) {
  .createWaifuPersonality .inputField {
    padding: 0.75rem;
  }

  .createWaifuPersonality .inputField--large
  {
    height: 80px;
  }
  .createWaifuPersonality .inputField--xlarge {
    height: 280px;
  }
  
}