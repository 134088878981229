.gift-popup {
    z-index: 15;
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    max-width: 500px; // Limit the width to match the design
    background: linear-gradient(to bottom right, #ff758c 0%, #ff7eb3 100%);
    border-radius: 25px; // Larger border-radius for a softer edge
    padding: 25px; // Adjust padding to match design
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); // Softer shadow
    color: white; // Assuming all text is white
  }
  
  .gift-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px; // Adjust spacing to match design
  }
  
  .gift-popup-close {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 30px; // Larger close button
    color: white; // White close button
  }
  
  .gift-popup-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr); // Two columns for the items
    gap: 15px; // Adjust gap to match design
  }
  
  .gift-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    text-align: center; // Center text below images
  }
  
  .gift-item img {
    width: 60px; // Adjust image size to match design
    height: auto;
    margin-bottom: 8px; // Space between image and text
  }
  
  .gift-price {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px; // Space between name and price
  }
  
  .gift-price img {
    margin-top: 0.3rem;
    width: 20px; // Larger diamond icon
    height: auto;
    margin-right: 5px;
  }
  .gift-item {
    transition: all 0.3s ease-in-out;
  
    &.gift-item-purchased {
      animation: purchaseAnimation 0.5s ease-in-out;
    }
  }
  
  @keyframes purchaseAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }