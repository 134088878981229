.reward-window {
  border-radius: 10px;
  text-align: center;
  background: linear-gradient(180deg, #5C75CC 0%, #203378 100%);
  
  .twitterWrapper {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }
  
  .clickHereIndicator {
    cursor: pointer;
    margin-left: 5px;
    font-size: 12px;
    color: #1DA1F2;
  }

  .row {
    margin-top: 1rem;
  }

  .grid {
    width: 100%;
    max-width: 540px;
    padding: 10px 0px 0px;
    margin: 0px;
    list-style: none;
    display: grid;
    gap: 10px;
    @media (min-width: 300px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 600px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: 900px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header {
    color: white;
  }

  span {
    color: white;
  }

  .characterReward {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 14px;
    z-index: 1;
  }

  .rewardItem {
    min-height: 185px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 19px;
    background: rgba(255, 255, 255, 0.1);
    padding: 10px;
    position: relative;
    color: white;

    input {
      color: black;
    }

    .rewardButton {
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      padding: 0px;
      gap: 5px;
      margin: 10px 0px;
      width: 80%;
      height: 34px;
      background: linear-gradient(rgb(255, 255, 255) 0%, rgb(217, 217, 217) 100%);
      box-shadow: rgba(209, 209, 209, 0.9) 0px 0px 15px, rgba(255, 255, 255, 0.4) 0px 0px 5px inset;
      border-radius: 14px;
      border: none;
      cursor: pointer;
      font-size: 16px;
      line-height: 34px;
      color: #203378;
    }

    .gems {
      margin-bottom: 2px;
      font-weight: 400;
      font-size: 20px;
      line-height: 22px;
      color: rgb(255, 255, 255);
      font-family: PPPangramSansRounded-Bold, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
    }
  }
}

.gemItemDaily {
  img {
    margin-top: 0.2rem;
    margin-bottom: 0.1rem;
    width: 120px;
    height: 80px;
    filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 5px 30px);
  }
}

.quest {
  img {
    margin-top: 0.2rem;
    margin-bottom: 0.1rem;
    height: 50px;
    filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 5px 30px);
  }
}

.dailyRewardTimer {
  display: flex;
  align-items: center;
  color: white;
  margin-bottom: 2rem;

  .dailyRewardButton {
    margin-left: 1rem;
    img {
      padding-left: 4px;
      max-width: 20px;
      max-height: 20px;
    }
    cursor: pointer;
    border: none;
    border-radius: 24px;
    align-self: center;
    font-family: PPPangramSansRounded-Bold, moderat;
    line-height: 20px;
    font-size: 16px;
    text-align: center;
    padding: 10px 20px;
    background: linear-gradient(180deg, rgba(224, 224, 224, 0) 0%, #d9d9d9 100%), rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.9), inset 0px 0px 5px rgba(255, 255, 255, 0.8);
    color: #203378;
    opacity: 1;
    -webkit-transition: box-shadow 0.3s ease;
    transition: box-shadow 0.3s ease;
    max-width: 130px;
    max-height: 80px;
  }

  .dailyRewardButton:hover {
    opacity: 1;
    box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.9), inset 0px 0px 5px rgba(255, 255, 255, 0.8);
  }
}

.quests {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  margin-top: 2rem;

  input {
    border: none;
    border-radius: 8px;
    font-family: moderat;
    font-size: 12px;
  }

  .instructions {
    font-family: moderat;
    font-size: 12px;
  }

  .questDescription {
    font-size: 12px;
  }

  h2 {
    color: white;
  }
}

.subscriptionInfo {
  font-size: 12px;
  font-family: 'Moderat';
  padding-left: 18px;
}

.rewards-modal {
  .modal-dialog {
    max-width: 800px;
    margin: 0.5rem;
    @media (min-width: 576px) {
      margin: 1.75rem auto;
    }
  }

  .modal-content {
    background: linear-gradient(180deg, #5C75CC 0%, #203378 100%);
    border-radius: 1rem;
    border: none;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0 15px 35px rgba(0, 0, 0, 0.3);
    }
  }


  .modal-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 1rem;
    display: flex;
    justify-content: center;
    position: relative;
    
    @media (min-width: 576px) {
      padding: 1.5rem;
    }
  
    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      margin: 0;
      color: white;
      
      @media (min-width: 576px) {
        font-size: 1.875rem;
      }
    }
  
    .close-button {
      background: none;
      border: none;
      padding: 0.5rem;
      cursor: pointer;
      transition: opacity 0.2s;
      color: white;
      position: absolute;
      right: 0.5rem;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.2s ease;
      
      @media (min-width: 576px) {
        right: 1.5rem;
      }
  
      &:hover {
        opacity: 1;
        transform: translateY(-50%) scale(1.1);
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }


  .modal-tabs {
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0;

    .tab {
      padding: 0.75rem 1rem;
      font-size: 0.875rem;
      background: none;
      border: none;
      font-weight: 500;
      cursor: pointer;
      position: relative;
      transition: all 0.2s;
      color: rgba(255, 255, 255, 0.7);
      transition: all 0.3s ease;
      
      @media (min-width: 576px) {
        padding: 1rem 2rem;
        font-size: 1rem;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        height: 2px;
        background: transparent;
        transition: background-color 0.2s;
        transition: all 0.3s ease;
      }

      &.active {
        color: white;

        &::after {
          background-color: white;
          box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
        }
      }

      &:hover:not(.active) {
        color: white;
        background: rgba(255, 255, 255, 0.05);
        transform: translateY(-1px);
      }
    }
  }

  .modal-body {
    padding: 1rem;
    
    @media (min-width: 576px) {
      padding: 1.5rem;
    }
  }

  .rewards-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    
    @media (min-width: 576px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
    }
  }

  .reward-card {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 0.75rem;
    padding: 0.75rem;
    text-align: center;
    transition: all 0.2s;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
      border-color: rgba(255, 255, 255, 0.2);
    }
    
    &.claimed:hover {
      transform: translateY(-2px);
      background: rgba(255, 255, 255, 0.2);
    }

    &.claimable:hover {
      transform: translateY(-4px);
      box-shadow: 0 8px 25px rgba(255, 255, 255, 0.2);
      background: rgba(255, 255, 255, 0.25);
    }


    @media (min-width: 576px) {
      padding: 1.25rem;
    }

    &.claimed {
      background: rgba(255, 255, 255, 0.15);
      border-color: rgba(255, 255, 255, 0.2);
    }

    &.claimable {
      background: rgba(255, 255, 255, 0.2);
      border-color: rgba(255, 255, 255, 0.3);
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(255, 255, 255, 0.1);
    }

    .reward-day {
      font-size: 0.75rem;
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 0.5rem;
      
      @media (min-width: 576px) {
        font-size: 0.875rem;
        margin-bottom: 0.75rem;
      }
    }

    .reward-gems {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.25rem;
      margin-bottom: 0.75rem;
      font-size: 1.25rem;
      font-weight: 600;
      color: white;
      
      @media (min-width: 576px) {
        gap: 0.5rem;
        margin-bottom: 1rem;
        font-size: 1.5rem;
      }

      img {
        width: 40px !important;
        height: 40px !important;
        
        @media (min-width: 576px) {
          width: 50px !important;
          height: 50px !important;
        }
      }
    }

    .reward-status {
      .claim-button {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(217, 217, 217, 0.9) 100%);
        color: #203378;
        border: none;
        padding: 0.375rem 0.75rem;
        border-radius: 0.375rem;
        font-weight: 500;
        cursor: pointer;
        font-size: 0.875rem;
        
        @media (min-width: 576px) {
          padding: 0.5rem 1rem;
          font-size: 1rem;
        }

        &:hover {
          background: white;
          transform: translateY(-1px) scale(1.02);
          box-shadow: 0 4px 15px rgba(255, 255, 255, 0.2);
        }

        &:active {
          transform: translateY(0) scale(0.98);
        }
      }

      .claimed-badge {
        background: rgba(255, 255, 255, 0.15);
        color: white;
        padding: 0.375rem 0.75rem;
        border-radius: 0.375rem;
        font-weight: 500;
        font-size: 0.875rem;
        
        @media (min-width: 576px) {
          padding: 0.5rem 1rem;
          font-size: 1rem;
        }
      }

      .timer, .locked {
        color: rgba(255, 255, 255, 0.7);
        font-size: 0.75rem;
        
        @media (min-width: 576px) {
          font-size: 0.875rem;
        }
      }
    }
  }

  .quests-grid {
    display: grid;
    gap: 0.75rem;
    
    @media (min-width: 576px) {
      gap: 1rem;
    }
  }

  .quest-card {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 0.75rem;
    padding: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
      border-color: rgba(255, 255, 255, 0.2);
    }
    
    @media (min-width: 576px) {
      padding: 1.25rem;
    }

    .quest-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      h3 {
        margin: 0;
        font-size: 1rem;
        font-weight: 600;
        color: white;
        
        @media (min-width: 576px) {
          font-size: 1.25rem;
        }
      }

      .quest-gems {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: white;
        font-weight: 600;
      }
    }

    .quest-description {
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 1rem;
      font-size: 0.75rem;
      
      @media (min-width: 576px) {
        font-size: 0.875rem;
      }
    }

    .quest-input {
      margin-bottom: 1rem;

      input {
        width: 100%;
        padding: 0.75rem;
        border-radius: 0.375rem;
        border: 1px solid rgba(255, 255, 255, 0.2);
        background: rgba(255, 255, 255, 0.1);
        color: white;
        transition: border-color 0.2s;
        transition: all 0.3s ease;

        &:hover {
          background: rgba(255, 255, 255, 0.15);
          border-color: rgba(255, 255, 255, 0.3);
        }

        &:focus {
          background: rgba(255, 255, 255, 0.2);
          border-color: white;
          box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.1);
          transform: translateY(-1px);
        }

        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }

    .quest-actions {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      transition: all 0.3s ease;
      
      @media (min-width: 576px) {
        flex-direction: row;
        gap: 1rem;
      }

      .social-link-button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        background: rgba(255, 255, 255, 0.15);
        border: none;
        border-radius: 0.375rem;
        color: white;
        font-weight: 500;
        cursor: pointer;
        transition: background-color 0.2s;
        width: 100%;
        
        @media (min-width: 576px) {
          width: auto;
        }

        img {
          width: 20px;
          height: 20px;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.25);
          transform: translateY(-1px);
          box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
        }

        &:active {
          transform: translateY(0) scale(0.98);
        }

        img {
          transition: transform 0.3s ease;
        }

        &:hover img {
          transform: scale(1.1);
        }
      }

      .claim-button {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(217, 217, 217, 0.9) 100%);
        color: #203378;
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 0.375rem;
        font-weight: 500;
        cursor: pointer;
        transition: background-color 0.2s;
        width: 100%;
        transition: all 0.3s ease;
        
        @media (min-width: 576px) {
          width: auto;
        }

        &:hover:not(:disabled) {
          background: white;
          transform: translateY(-1px) scale(1.02);
          box-shadow: 0 4px 15px rgba(255, 255, 255, 0.2);
        }

        &:active:not(:disabled) {
          transform: translateY(0) scale(0.98);
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
          &:hover {
            transform: none;
            box-shadow: none;
          }
        }
      }
    }

    .claimed-badge {
      background: rgba(255, 255, 255, 0.15);
      color: white;
      padding: 0.5rem 1rem;
      border-radius: 0.375rem;
      font-weight: 500;
      text-align: center;
    }
  }
}