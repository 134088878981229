.sidebar {
  .diary {
    overflow-y: auto; height: 90vh;
  }

  .entry {
    background-color: white;
    border-radius: 16px;
    padding: 20px;
    margin-bottom: 10px;
    font-family: 'Moderat', sans-serif;
    color: rgb(34, 34, 34);
    cursor: pointer;
  }

  .closeEntry {
    border-radius: 14px;
    border: none;
    position: absolute;
    background: rgba(0, 0, 0, 0.08);
    color: white;
    top: 10px;
    left: 10px;
    cursor: pointer;
  }

  .entry p {
    font-size: 14px;
    color: rgb(34, 34, 34);
    margin: 0;
  }
p{
  color: rgb(34, 34, 34);
}
  .entry h4 {
    font-size: 12px;
    font-weight: normal;
    color: rgba(34, 34, 34, 0.6);
    margin: 10px 0 0;
  }

  p {
    background-color: white;
    border-radius: 4px;
    padding: 10px;
    font-family: moderat;
    font-size: 16px;
  }

  h1 {
    font-size: 20px;
    text-align: center;
  }

  h4 {
    margin-top: 2rem;
    font-size: 14px;
  }

  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  background: rgba(255, 255, 255, 0.16);
  backdrop-filter: blur(25px);
  padding: 20px;
  transition: transform 0.3s ease-out;
  transform: translateX(-100%);
  color: white;

  .close {
    border-radius: 14px;
    border: none;
    position: absolute;
    background: rgba(0, 0, 0, 0.08);
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .content {
    overflow-y: auto;
    height: calc(100% - 40px); /* minus the padding */
  }
}

.sidebar.open {
  transform: translateX(0);
}

/* On mobile devices */
@media (max-width: 600px) {
.sidebar {
  width: 100%;
}
}