#oniichat-pp-wrapper {
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;

  .timer {
    position: absolute;
    color: #e9ecef;
    top: 93px;
    right: -630px;
    left: 20px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
    text-align: left;
    background: linear-gradient( rgb(43, 31, 100) 45%, rgb(48, 24, 115) 103%), linear-gradient(rgb(92, 116, 204) 0%, rgb(33, 52, 122) 100%);
    border-radius: 10px;
    z-index: 1;
    overflow: hidden;

    .timer-text {
      font-size: 34px;
      font-weight: bold;
      color: #c23836; // Adjusted red color
      margin-top: 11px; // Adjust to increase space from the top
      margin-bottom: -10px; // Adjust to increase space from the bottom
    }

    .save-money {
      text-align: center;
    }
  }

  .Save {
    position: absolute;
    color: #e9ecef;
    top: 20px;
    left: -630px;
    right: 20px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
    text-align: left;
    background: linear-gradient( rgb(255, 162, 109) -9%, rgb(131, 113, 242) 45%, rgb(53, 37, 98) 103%), linear-gradient(rgb(92, 116, 204) 0%, rgb(33, 52, 122) 100%);
    border-radius: 10px;
    z-index: 1;
    overflow: hidden;
  }
  .highlight-red {
    color: yellow; /* Red color */
    font-weight: bold;
  }
  .Red {
    color: yellow;
  }

  /* Existing styles for the text inside .Save */
  .Save .Header1,
  .Save .Header2 {
    margin: 0;
    line-height: 1.2;
    font-weight: bold;
  }

  .Save .Header2 {
    margin-top: -3px;
  }

  .Save .Paragraph1,
  .Save .Paragraph2 {
    margin: 0;
    margin-top: 4px;
    line-height: 1.2;
  }

  .Save .Paragraph2 {
    margin-top: -2px;
  }

  /* Rest of your styles... */
  
  .side-image {
    position: absolute;
    height: auto;
  }

  .left-image {
    left: 0px;
    top: 60%;
    transform: translateY(-50%);
    width: 370px;
  }

  .right-image {
    right: -35px;
    top: 90%;
    transform: translateY(-50%);
    width: 430px;
  }
  .limited-of {
    display: none;
  }
  .timer-two {
    display: none;
  }

  @media (max-width: 1200px) {
    .left-image {
      display: none;
    }
    .Save {
      top: 100px;
      left: -600px;
      right: 20px;
      max-width: 230px;
    }
    .timer {
      top: 345px;
      left: -600px;
      right: 20px;
      max-width: 230px;
    }
    .limited-of {
      display: none;
    }
    .timer-two {
      display: none;
    }
   }

   @media (max-width: 1000px) {
    .right-image {
      display: none;
    }
    .Save {
      display: none;
    }
    .timer {
      display: none;
    }
    .limited-of {
      display: block;
      color: #ffffff;
      text-align: center;
      margin-bottom: 0px;
      margin-top: 0px;
    }
    .timer-two {
      display: block;
      color: #df3409;
      text-align: center;
    }
  }


.oniichat-plus-panel {
  .onii-ultimate {
    margin-bottom: 10px;
  }
  .termsText{
    font-size: 10px;
    color: #ffffff;
    font-family: moderat;
    margin-top: -10px;
  }
    .subscribed-message {
        padding: 20px;
        background-color: #e9ecef;
        border-radius: 10px;
        margin: 20px 0;
        
        p {
          margin: 0;
          color: #333;
          font-size: 16px;
          text-align: center;
        }
      }
      .subscription-content {
        background: transparent;
        border-radius: 20px;
        padding: 20px;
        text-align: center;
        width: 100%; /* Use 100% width for responsiveness */
        max-width: 350px; /* Set a max-width for larger screens */
        margin: auto; /* Center the content within the panel */
      }
  
    h2 {
      color: #ffffff;
      font-size: 24px;
      margin-bottom: 15px;
    }
  
    .features {
      list-style: none;
      padding: 0;
      margin-bottom: 20px;
      text-align: left;
  
      li {
        margin-bottom: 10px;
        font-size: 16px;
        color: #ffffff;
  
        &.unavailable {
          color: #bbb;
        }
  
        &:before {
          content: '✓';
          color: #2fb74a;
          font-weight: bold;
          margin-right: 5px;
        }
      }
    }
  
    .plan-selection {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;

      .month {
        position: absolute;
        top: 50%;           /* Adjust 'top' to move up or down */
        right: 20px;        /* Adjust 'right' to move left or right */
        transform: translateY(-50%);  /* Vertically center the price */
        font-size: 9px;    /* Adjust font size as needed */
        color: #b1b6c1;
      }      

      .plan-option .month-one {
        /* Specific adjustments for price-one */
        top: 62%;
        right: 12px;
      }
      
      .plan-option .month-two {
        /* Specific adjustments for price-two */
        top: 62%;
        right: 12px;
      }

      .cent {
        position: absolute;
        top: 50%;           /* Adjust 'top' to move up or down */
        right: 20px;        /* Adjust 'right' to move left or right */
        transform: translateY(-50%);  /* Vertically center the price */
        font-size: 14px;    /* Adjust font size as needed */
        color: #ffffff;     /* Set text color */
      }      

      .plan-option .cent-one {
        /* Specific adjustments for price-one */
        top: 40%;
        right: 25px;
      }
      
      .plan-option .cent-two {
        /* Specific adjustments for price-two */
        top: 40%;
        right: 25px;
      }
      

      .plan-option .price {
        position: absolute;
        top: 50%;           /* Adjust 'top' to move up or down */
        right: 20px;        /* Adjust 'right' to move left or right */
        transform: translateY(-50%);  /* Vertically center the price */
        font-size: 35px;    /* Adjust font size as needed */
        color: #ffffff;     /* Set text color */
      }      

      .plan-option .price-one {
        /* Specific adjustments for price-one */
        top: 50%;
        right: 45px;
      }
      
      .plan-option .price-two {
        /* Specific adjustments for price-two */
        top: 50%;
        right: 45px;
      }
      


      .plan-option {
        position: relative;
        text-align: left;
        border: 1px solid #71149f;
        border-radius: 7px;
        border-width: 1.5px;
        padding: 10px 20px;
        cursor: pointer;
        background-color: rgba($color: #b14343, $alpha: 0.3);
        transition: all 0.3s;
        font-weight: bold;
        color: #fb15f7bd;
        .price-one, .price-two {
          text-justify: right;
        }
        .badge1, .badge2 {
          position: relative;
          background-color: #fb15f7bd;
          color: white;
          border-radius: 4px;
          padding: 4px 8px;
          font-size: 11px;
          transform: translate(25%, -25%);
          top: -3px;
          right: -8px;
        }

        .annual-writing {
          font-size: 11px;
          text-decoration: line-through;
          color: #b1b6c1;
          margin-bottom: 0px;
          margin-top: -5px;
        }
  
        &:hover {
          background-image: linear-gradient(rgb(54, 76, 77) 0%, rgb(131, 113, 242) 100%), linear-gradient(rgb(92, 116, 204) 0%, rgb(33, 52, 122) 100%);
          transition: background-image 0.4s ease, transform 0.3s ease;
          transform: scale(1.05);  // Slight zoom-in effect
        }
        
        &.selected {
          background-image: linear-gradient( rgb(131, 113, 242) 45%, rgb(53, 37, 98) 103%), linear-gradient(rgb(92, 116, 204) 0%, rgb(33, 52, 122) 100%);
          color: #a311a1d8;
          box-shadow: 0px 0px 15px rgba(131, 113, 242, 0.4);
          transition: box-shadow 0.3s ease, transform 0.3s ease;
        }
        
        &:hover, &.selected {
          transform: scale(1.05); // Zoom-in for both hover and selected states
        }
        
      }
    }
  
    .subscribe-button-container {
      position: relative;
      margin-bottom: 10px;
      margin-top: 5px;
  
      .subscribe-button {
        background-color: #b71aac;
        color: white;
        border: none;
        border-radius: 7px;
        padding: 15px 20px;
        font-size: 18px;
        cursor: pointer;
        width: 100%;
        font-weight: bold;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: darken(#2583dc, 10%);
        }
      }
    }
  
    .cancel-info {
      color: #ffffff;
      font-size: 14px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
}

#oniichat-pp-wrapper, .oniichat-plus-panel {
  background: linear-gradient(270deg, rgb(131, 113, 242), rgb(53, 37, 98), rgb(92, 116, 204), rgb(33, 52, 122));
  background-size: 1000% 1000%;
  animation: gradientShift 60s ease infinite;
}

#oniichat-pp-wrapper {
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
}

.oniichat-plus-panel {
  /* Ensure the same animation is applied to the panel */
  animation: gradientShift 60s ease infinite;
}

.oniichat-plus-panel .subscription-content {
  background: transparent; /* Set to transparent to allow the gradient animation to show */
  border-radius: 20px;
  padding: 20px;
  text-align: center;
  width: 100%; /* Use 100% width for responsiveness */
  max-width: 350px; /* Set a max-width for larger screens */
  margin: auto; /* Center the content within the panel */
}

/* Gradient animation */
@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}