
.headline
{
  font-size: 20px;
  text-align: center;
}
.editButton, .deleteButton {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  margin: 5px;

  &:hover {
    color: #ddd;
  }
}
.previewImage {
  max-width: 150px;  
  max-height: 150px;
  display: block; 
  margin-bottom: 1rem;
  margin-right: 3rem;
  object-fit: cover; // Ensures image covers the entire container
  border-radius: 10px; // Adds slightly rounded corners
  transition: transform .2s; // Animation for hover transform
}

.previewImage:hover {
  transform: scale(1.1); // Slightly enlarges image on hover
}
.customStoryModal {
  .customStoryForm {
    h3 {
      font-size: 24px;
      margin-bottom: 1rem;
    }

    .customStoryInput {
      margin-bottom: 1rem;
    }

    .customStoryTextArea {
      margin-bottom: 1rem;
    }

    .customStoryCheckbox {
      margin-bottom: 1rem;

      .ant-checkbox-wrapper {
        display: flex;
        align-items: center;

        .ant-checkbox {
          margin-right: 0.5rem;
        }
      }
    }

    .customStoryVisibility {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      span {
        margin-right: 0.5rem;
        font-weight: bold;
      }

      .customStorySelect {
        width: 150px;
      }
    }

    .customStoryFileUpload {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      .customStoryUploadButton {
        margin-right: 0.5rem;
        background: #e0e0e0;
        color: #5e5e5e;
        border: none;
        box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.8);
        &:hover {
          box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.9);
        }
      }
      .ant-upload {
        margin-right: 0.5rem;
      }
    }

    .customStoryButtons {
      display: flex;
      justify-content: flex-end;
      margin-top: 2rem;

      button {
        margin-left: 1rem;
      }
      .customStorySubmitButton {
  display: flex;
  align-items: center;
    img{
    width: 20px;
    height: auto;
    margin-left: 0.1rem;
    margin-right: 0.3rem;
    }

      }
    }

    .customStoryNote {
      margin-top: 1rem;
      font-size: 14px;
      color: #888;
    }
  }
}
.story.selected {
  border: 3px solid #00FF00;  // Green border for selected stories
}

.story.bought {
  border: 3px solid #0000FF;  // Blue border for bought stories
}
.buyButton{
  cursor: pointer;
        margin-top: 0.8rem;
        margin-left: 0.5rem;
        margin-right: 1rem;
        border: none;
        border-radius: 24px;
        align-self: center;
        font-family: PPPangramSansRounded-Bold, moderat;
        line-height: 20px;
        font-size: 16px;
        text-align: center;
        padding: 15px 20px;
        background: linear-gradient(
            180deg,
            rgba(224, 224, 224, 0) 0%,
            #d9d9d9 100%
          ),
          rgba(255, 255, 255, 0.9);
        box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.9),
          inset 0px 0px 5px rgba(255, 255, 255, 0.8);
        color: #5e5e5e;
        opacity: 1;
        -webkit-transition: box-shadow 0.3s ease;
        transition: box-shadow 0.3s ease;
        max-width: 130px;
        max-height: 80px;
        margin-bottom: 3rem;
}
.buyButton:hover {
  opacity: 1;
  box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.9),
    inset 0px 0px 5px rgba(255, 255, 255, 0.8);
}
.story-sidebar {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  background: rgba(255, 255, 255, 0.16);
  backdrop-filter: blur(25px);
  padding: 20px;
  color: white;
  transition: transform 0.3s ease-out;
  transform: translateX(-100%);
  overflow: auto;
  box-sizing: border-box;
}
.gemPrice{
  margin-top: 1rem;
  gap: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width: 20px;
  }
}
.open {
  transform: translateX(0);
}

.storyStore {
  max-height: 90vh;
}

.storyGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 3rem;
}
.fullStoryImageWrapper {
  position: relative;
  width: 100%;
  height: 100%; /* or any height you prefer */
  overflow: hidden;
}

.fullStoryImage {
  width: 100%;
  height: auto;
}
.story, .fullStoryImage {
  flex: 1 0 48%;
  height: 200px;
  background-size: cover;
  background-position: center;
  border-radius: 16px;
}
.storyImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.story {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.storyInfo {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  box-sizing: border-box;
}

.storyInfo h4, .storyInfo p {
  margin: 0;
  line-height: 1.2;
}

.close, .closeStory {
  border-radius: 14px;
  border: none;
  position: absolute;
  background: rgba(0, 0, 0, 0.08);
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.closeStory {
  left: 10px;
  right: auto;
}

.fullStory {
  text-align: center;
}

.fullStory h2 {
  font-size: 24px;
  margin: 1rem 0;
}

@media (max-width: 600px) {
  .story-sidebar {
      width: 100%;
  }

  .story, .fullStoryImage {
      flex: 1 0 100%;
  }
}
.unselectButton {
  margin-bottom: 4rem;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  border: none;
  color: #fff;
  font-family: PPPangramSansRounded-Bold, moderat;
  line-height: 20px;
  font-size: 20px;
  text-align: center;
  padding: 20px 30px;
  z-index: 1;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}


.selectButton {
  margin-bottom: 4rem;
  z-index: 1;
  cursor: pointer;
  border: none;
  border-radius: 24px;
  align-self: center;
  font-family: PPPangramSansRounded-Bold, moderat;
  line-height: 20px;
  font-size: 20px;
  text-align: center;
  padding: 20px 30px;
  background: linear-gradient(180deg, rgba(224, 224, 224, 0) 0%, #d9d9d9 100%), rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.9), inset 0px 0px 5px rgba(255, 255, 255, 0.8);
  color: #5e5e5e;
  opacity: 1;
  transition: box-shadow 0.3s ease;
}

.selectButton:hover {
  opacity: 1;
  box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.9), inset 0px 0px 5px rgba(255, 255, 255, 0.8);
}
