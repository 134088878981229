


.locked-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    padding: 10px;
    text-align: center;
    color: white;
  }

  .lock-info {
    text-align: center;
    color: white;
    padding: 10px;
    background: rgba(0, 0, 0, 0.75); // More opaque to stand out
    border-radius: 10px;
    margin-top: -20%; // Adjust based on your design
  }
  
  .unlock-requirement {
    font-size: 16px;
    margin-bottom: 12px;
  }
  .lock-icon {
    width: 50px; // Or the size you prefer
    height: 50px;
    opacity: 0.75;
  }
  .unlock-info {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;
    color: white;
  }
  
  .unlock-button {
    background: rgba(255, 255, 255, 0.2); /* Glass effect */
    border: none;
    border-radius: 25px;
    padding: 8px 16px; /* Slightly smaller padding */
    font-size: 14px; /* Smaller font size */
    color: #FFF;
    cursor: pointer;
    backdrop-filter: blur(10px); /* Frosted glass look */
    margin-top: 0.5rem;
    transition: all 0.3s ease; /* Smooth transition for any changes */
  }
  
  /* Responsive adjustments for smaller screens */
  @media (max-width: 600px) {
    .unlock-button {
      padding: 6px 12px; /* Even smaller padding for mobile */
      font-size: 12px; /* Smaller font size for mobile */
    }
  }
  .card-info, .character-slide-info {
  z-index: 10; // Make sure this is above the locked-overlay
  position: relative;
}
  .gems-amount {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #6200ea; /* Your gem color */
    border-radius: 10px; /* Circular if the content is short */
    padding: 5px 10px;
    margin-left: 10px;
  }
  @keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
  .swiper-slide {
  opacity: 1;
    transition: opacity 0.5s ease;
    position: relative;  /* Add this line */
    width: auto;
    height: auto;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
  .newMessageIndicator {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #0D6EFD	;
    color: white;
    border-radius: 50%;
    padding: 5px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    height: 20px;
    z-index: 1;  /* Add this line */
  }
  .card-info {
    padding: 5px;
    font-size: 16px;
    text-align: center;
    color: white
  }
  .character-slide-card {
    width: 100%;
    left: 12px;
    height: 100%;
    min-height: 290px;
    top: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .editBtn{
    z-index: 1;
    cursor: pointer;
    border: none;
    border-radius: 24px;
    align-self: center;
    font-family: PPPangramSansRounded-Bold, moderat;
    line-height: 20px;
    font-size: 20px;
    text-align: center;
    background: linear-gradient(180deg, rgba(224, 224, 224, 0) 0%, #d9d9d9 100%),
      rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.9),
      inset 0px 0px 5px rgba(255, 255, 255, 0.8);
    color: #5e5e5e;
    opacity: 1;
    -webkit-transition: box-shadow 0.3s ease;
    transition: box-shadow 0.3s ease;
    align-items: center;
    height: 40px;
    width: 60px;
    font-size: 16px;
    margin-top: 0.5rem;
  }
  .editBtn:hover {
    opacity: 1;
    box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.9),
      inset 0px 0px 5px rgba(255, 255, 255, 0.8);
  }
  .card-footer {
    position: absolute;
    font-size: 12px;
    color: white;
    font-family: moderat;
    height: 18px;
    left: 0;
    bottom: 0;
    width: 100%; /* Ensure it spans the entire width */
    margin-left: 0.5rem;
    margin-bottom: 0.2rem;
  }
  .character-slide-info {
    overflow: auto;
    cursor: pointer;
    width: 100%;
    font-family: moderat;
    font-size: 13px;
    text-align: center;
    color: white;
  }
  
  /* Responsive adjustments for smaller screens */
  @media (max-width: 600px) {
    .character-slide-info {
      font-size: 11px; /* Smaller font size for mobile */
      padding: 5px; /* Add padding for better readability on small screens */
    }
  }
  
  /* Further adjustments for even smaller screens, if necessary */
  @media (max-width: 400px) {
    .character-slide-info {
      font-size: 10px; /* Even smaller font size for very small screens */
    }
  }

  .charater-avatar-image-locked {
    
    @media (min-width: 300px) {
      width: 90px;
      height: 90px;
    }
    @media (min-width: 600px) {
      width: 100px;
      height: 100px;
    }
    @media (min-width: 900px) {
      width: 100px;
      height: 100px;
    }
    border-radius: 14px;
  }
  .image-placeholder, .charater-avatar-image {
    
    @media (min-width: 300px) {
      width: 120px;
      height: 120px;
    }
    @media (min-width: 600px) {
      width: 150px;
      height: 150px;
    }
    @media (min-width: 900px) {
      width: 150px;
      height: 150px;
    }
    border-radius: 14px;
  }

  .character-image-name {
    font-size: 20px;
  }
  .description {
    font-family: moderat;
  }

}

.character-slide-card.with-picture {
  border: 3px solid gold; /* Thicker golden border */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.6); /* Golden glow effect */
}

.picture-badge {
  position: absolute;
  top: 40px; /* Adjust for optimal positioning */
  left: 1px;
  transform: rotate(-45deg); /* Diagonal orientation */
  background-image: linear-gradient(92deg, rgba(214, 165, 253, 0.9) 0%, rgba(137, 99, 245, 0.9) 72%);
  color: white;
  padding: 2px 6px; /* Reduced padding for a smaller badge */
  border-radius: 4px; /* Smoother border radius for subtlety */
  font-size: 10px; /* Smaller font size for a less prominent look */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Lighter shadow for subtleness */
  z-index: 2;
  letter-spacing: 0.3px; /* Slight letter spacing for legibility */
  text-transform: uppercase; /* Uppercase for a compact and bold statement */
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2); /* Subtle text shadow for depth */
}

.video-badge {
  position: absolute;
  top: 40px; /* Adjust for optimal positioning */
  left: 1px;
  transform: rotate(-45deg); /* Diagonal orientation */
  background-image: linear-gradient(90deg, rgba(77, 145, 255, 0.9) 0%, rgba(57, 178, 150, 0.9) 72%);
  color: white;
  padding: 3px 7px; /* Slightly larger for video visibility */
  border-radius: 4px;
  font-size: 11px; /* A bit larger for better readability on videos */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 2;
  letter-spacing: 0.3px;
  text-transform: none; /* Regular case for distinction from picture badge */
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

@keyframes premiumBorder {
  0%, 100% { 
    border-color: rgba(77, 145, 255, 0.9); /* Blue */
    box-shadow: 0 0 15px rgba(77, 145, 255, 0.9); /* Blue glow */
  }
  50% { 
    border-color: rgba(57, 178, 150, 0.9); /* Green */
    box-shadow: 0 0 15px rgba(57, 178, 150, 0.9); /* Green glow */
  }
}

.character-slide-card.with-video {
  border: 3px solid rgba(77, 145, 255, 0.9); /* Starting color: Blue */
  border-radius: 12px;
  animation: premiumBorder 6s infinite linear; /* Apply the animation */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Smooth transition for hover in and out */
}


/* Additional styling for responsiveness and aesthetic enhancement */
@media (max-width: 600px) {
  .character-slide-card.with-video {
    border-width: 2px; /* Thinner border on smaller screens */
  }
}