.signInOut {

.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 10px;
  background-color: #f0f2f5;
  max-width: 1200px; /* Optional: Added max-width to constrain the total width */
  margin: 0 auto; /* Center the container within the viewport */

}

.login-container {
  width: 100%;
  max-width: 350px;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.displayType {
  color: #555;
  font-size: 14px;
  margin-bottom: 16px;
}
.logo {
  width: 140px;
  margin: 0 auto;
}
.forgotPasswordLink {
  display: block;
  width: 100%; /* Ensures the forgot password link spans the full width */
  text-align: center; /* Center the text within the container */
  margin-bottom: 10px; /* Add margin below if needed */
}

.login-header {
  margin-top: 30px;
  text-align: center;
}

.login-form-container {
  padding: 15px;
  border-radius: 5px;
}

.login-options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.login-option {
  width: 48%;
}

.or-divider {
  position: relative;
  text-align: center;
  margin: 15px 0;
}

.or-divider span {
  background-color: #fff;
  padding: 0 10px;
  position: relative;
  z-index: 1;
}

.or-divider::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 1px;
  background-color: #ddd;
  z-index: 0;
}

.input {
  height: 40px;
}

.submit-button {
  width: 100%;
  height: 40px;
}

.forgot-password {
  text-align: center;
}
  .iconButton {
    width: 48px;
    height: 48px;
    border: none;
    .anticon {
      font-size: 24px;
    }
  }

  .inputField {
    border-radius: 8px;
  }

  .submitButton {
    width: 100%;
    border-radius: 8px;
  }
    .modalContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      .ant-form-item {
        margin-bottom: 10px;
      }
      button {
        width: 100%;
        margin-bottom: 8px;
        &:disabled {
          background-color: #ccc;
        }
      }
      .registerLoginLink {
        display: flex;
        justify-content: center; /* Centers the content horizontally */
        width: 100%; /* Ensures the link spans the full width */
        text-align: center; /* Aligns the text within the container */
        margin-top: 10px; /* Adjusts the space above the link */
        align-items: center; /* Ensures vertical centering */
      }
      
      .registerLoginLink a {
        color: #1890ff;
        cursor: pointer;
        text-decoration: none; /* Removes the default underline */
      }
      
      .registerLoginLink a:hover {
        text-decoration: underline; /* Adds underline on hover */
      }      
    }
  }
  .iconButton {
    height: 48px;
    border: 1px solid #ccc;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: -15px;
    padding: 0 20px;
  }
  
  .googleIcon {
    margin-right: 10px;
  }
  
  .inputField {
    margin-top: 0px;
    border-radius: 8px;
  }
  form{
    display: flex;
    flex-direction: column;
  }
  .submitButton {
    width: 100%;
    border-radius: 8px;
    margin: 0 auto;
  }
  .logo{
    z-index: 1;
    margin-left: 0.3rem;
  width: 160px;
  }
  .formContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .inputField, .submitButton {
    width: 280px; /* Or any other value in px depending on your layout */
    height: 40px;
  }

  .ctaContainer {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  
  .ctaTitle {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: -2px;
  }
  
  .ctaText {
    font-size: 18px;
    margin-bottom: 7px;
  }
  
  .ctaBonus {
    color: #0D6EFD;
    font-weight: bold;
  }
  
  .iconBonus {
    margin: 0.2rem;
    width: 24px;
    vertical-align: middle;
  }
  .modalPanels {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 850px; /* Adjust to ensure the modal is wide enough */
    height: 100%; /* Make sure the panels take up the full height of the modal */
    margin: 0 auto;
    border: 1px solid #ccc; /* Add border to the entire panel */
    border-radius: 10px;
    overflow: hidden;
  }
  
  .imagePanel {
    flex: 1; /* Ensure the image panel takes up exactly 50% of the space */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    overflow: hidden;
    background-color: #000; /* Optional: Set a background color to match the example */
    position: relative; /* Allows logo positioning at the bottom */
  }
  
  .panelImage {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image maintains its aspect ratio and fills the space */
    border-radius: 10px 0 0 10px; /* Rounded corners on the left side */
  }
  
  /* New styles for the Oniichat logo */
  .logoWrapper {
    position: absolute;
    bottom: 20px; /* Increase the distance from the bottom */
    width: 100%;
    display: flex;
    justify-content: center; /* Centers the logo horizontally */
  }
  
  .oniichatLogo {
    width: 120px; /* Set a smaller width for the logo */
    height: auto;
    padding: 5px;
    border-radius: 15px; /* Increased rounding of corners */
  }  
  
  /* Content panel remains unchanged */
  .contentPanel {
    flex: 1; /* Ensure the form panel takes up 50% of the space */
    background-color: white;
    padding: 15px;
    border-radius: 0 10px 10px 0; /* Rounded corners on the right side */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  /* Media Query for Mobile Screens */
  @media (max-width: 768px) {
    .imagePanel {
      display: none; /* Hide the image panel on small screens */
    }
  
    .contentPanel {
      flex: 1;
      border-radius: 10px; /* Reset the border radius for the entire panel on mobile */
    }
  
    .modalPanels {
      flex-direction: column; /* Stack the panels vertically if needed (optional) */
      max-width: 100%; /* Make the modal take full width on small screens */
      border-radius: 10px;
      height: auto; /* Adjust height for mobile screens */
    }
  }  