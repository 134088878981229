.scrollable-topics {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    .category-buttons { // If buttons are inside this container
      display: flex;
      justify-content: center; // Center buttons horizontally
      width: 100%; // Take full width of parent
    }
    .close-button {
      margin-right: 10px; // Space between close button and topics container
      cursor: pointer;
      color: white;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 50%;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 20;
  
      &:hover {
        background: rgba(0, 0, 0, 0.8);
      }
    }
  
    .chevron {
      z-index: 10;
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding: 5px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      color: white;
      &:hover {
        background: rgba(0, 0, 0, 0.75);
      }
    }
  
    .left {
      left: 40px;
    }
  
    .right {
      right: 10px;
    }
  
    .topics-container {
      flex-grow: 1; // Allow the container to take up available space
      display: flex;
      align-items: center;
      overflow-x: auto;
      scroll-behavior: smooth;
      -ms-overflow-style: none;
      scrollbar-width: none;
      margin: 0 auto; // Center the container
  
      &::-webkit-scrollbar {
        display: none;
      }
  
    }
  
    // Responsive sizing for the buttons
    @media (max-width: 768px) {
      .topic-button {
        padding: 6px 12px;
        font-size: 12px;
      }
    }
  }
  .topic-button {
    flex: 0 0 auto; // Don't grow, don't shrink, base on width auto
    background-color: #7b4f91;
    color: white;
    border: none;
    padding: 5px 13px;
    margin: 10px;
    border-radius: 15px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-2px);
      background-color: lighten(#7b4f91, 10%);
    }

    &:focus {
      outline: none;
    }
  }