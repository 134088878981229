
.reportProblemButton {
  background: none;
  border: none;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 200px;
}
.coupon-section {
  margin: 20px 0;

  .coupon-input-wrapper {
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 25px;
    padding: 10px 15px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    transition: all 0.3s ease;

    &:focus-within {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
    }

    .coupon-icon {
      color: white;
      margin-right: 10px;
    }

    input {
      flex-grow: 1;
      background: transparent;
      border: none;
      color: white;
      font-size: 16px;
      outline: none;

      &::placeholder {
        color: rgba(255, 255, 255, 0.6);
      }
    }

    .apply-icon {
      color: white;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        transform: translateX(3px);
      }
    }
  }
}

.reportProblemButton:hover {
  color: #0056b3;
}

.payWithCreditCard {
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s;
  width: 100%;
  margin-top: 20px;
}

.payWithCreditCard:hover {
  background-color: #0056b3;
}

.payWithCreditCard:active {
  background-color: #004085;
}

.payWithCreditCard i {
  margin-right: 8px;
}
  // Styles for ProblemReportForm
  .problemReportModal {
    // Add styles for the modal if needed
  }
  
  .problemReportForm {
    // Add styles for the form if needed
  }
  
  .problemReportInput {
    // Add styles for the input fields if needed
  }
  
  .problemReportTextArea {
    // Add styles for the text area if needed
  }
  
  .problemReportButtons {
    // Add styles for the buttons if needed
  }
  
  .problemReportCancelButton {
    // Add styles for the cancel button if needed
  }
  
  .problemReportSubmitButton {
    // Add styles for the submit button if needed
  }  
