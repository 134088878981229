.container {
    background: linear-gradient(180deg, #5C75CC 0%, #203378 100%);
    font-family: PPPangramSansRounded-Bold, moderat;
    height: 100vh; /* Adjusting for padding */
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto; /* Enabling vertical scroll */
}
.heading {
    text-align: center;
    font-size: 42px;
    color: white;
    margin-bottom: 20px;
    width: 70%
}

.subheading {
    font-size: 38px;
    color: white;
    margin-bottom: 15px;
}

.supportLink {
    color: white;
    margin-top: 10px;
}
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  

.cancelLink {
    font-size: 12px;
    color: white;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
    transition: color 0.3s ease;
}

.cancelLink:hover {
    color: #f0f0f0;
}
/* Media Queries */
@media only screen and (max-width: 768px) {
    .container {
        max-height: 100vh;  /* Setting a maximum height for mobile */
        height: auto; /* Change to auto so the content dictates the height */
        overflow-y: auto;
    }
    
    .heading, .subheading {
        font-size: 24px; /* Smaller text */
        width: 90%; /* More width for smaller screens */
    }
    
    .row {
        flex-direction: column; /* Stack columns */
    }
    
    .col-lg-4 {
        margin-bottom: 20px; /* Space between stacked columns */
    }
}