.SettingsPage{
  overflow-y: scroll;
  height: 100vh;
  background: linear-gradient(180deg, #5C75CC, #203378);
}
.settingsWrapper {
  z-index: 0;
  color: white;
  display: flex;
  flex-direction: column; // Stack MainNav and settings-container on top of each other
  align-items: center;
margin-bottom: 2rem;
overflow: hidden;

  .settings-container {
    width: 80%;
    max-width: 1024px;
    margin-top: 2em; // Add space between the nav and the container
    padding: 2em;
    background: rgba(76, 86, 130, 0.25);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    border: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    gap: 20px;// Ensure the container stacks above any other positioned elements
    @media (max-width: 768px) { // Adjusting for mobile view
      flex-direction: column;
      align-items: center;
    }
    .section {
      flex-basis: calc(50% - 10px);
      background: rgba(255, 255, 255, 0.05);
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

      .section-header {
        margin-bottom: 30px;
        color: #ccd6f6;
        font-size: 1.5em;
      }
      .subscription-panel {
        color: black;
  
        // If you have specific selectors within your panel, ensure they have the color set to white
        h3, p, div, span, a { // Example of selectors, adjust as per your actual HTML structure
          color: black;
        }
      }
      .option-item {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        font-size: 1em;

        .option-icon {
          margin-right: 10px;
          color: #ccd6f6;
        }

        .settings-input,
        .settings-select {
          flex-grow: 1; // Allow input and select to fill space
          padding: 10px;
          background: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.3);
          border-radius: 8px;
          color: #D1E0FF;
          margin-left: 10px; // Add some space between icon and input/select

          &:focus {
            outline: none;
            border-color: #AABFFF;
          }
        }
      }

      .choose-button {
        padding: 10px 20px;
        background: #ffffff;
        color: #5C75CC;
        font-size: 1em;
        border: none;
        border-radius: 15px;
        font-weight: bold;
        cursor: pointer;
        text-align: center;
        display: inline-block;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        transition: all 0.3s ease;
        margin-inline: 0.2rem;
        &:hover {
          background: #e2e6ea;
        }
        margin-top: 20px; // Add some space above the button
      }
    }
  }
  .subscription-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    background: rgba(92, 117, 204, 0.2);
    backdrop-filter: blur(5px);
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    margin: 1rem auto;
    width: auto;
    max-width: 500px;
  
    // Adjusting the radial gradient to fit the section
    position: relative;
    z-index: 1;
    &:before {
      content: '';
      position: absolute;
      top: 0; // Match the top edge of the parent container
      right: 0; // Match the right edge of the parent container
      bottom: 0; // Match the bottom edge of the parent container
      left: 0; // Match the left edge of the parent container
      background: radial-gradient(circle at center, rgba(255, 255, 255, 0.25) 20%, rgba(92, 117, 204, 0.2) 70%);
      z-index: -1;
      border-radius: inherit; // Follows the border radius of the container
    }
  
  
    .subscription-info {
      position: relative;
      z-index: 2; // Higher z-index to ensure content appears above the pseudo-element
  
      .SettingsCTA {
        margin-bottom: 0.5rem;
        width: 40px;
        height: auto;
      }
  
      .subscription-text {
        font-size: 0.9rem;
        color: white;
        padding: 0 0.5rem;
  
        @media (min-width: 576px) {
          font-size: 1rem;
        }
      }
    }
  

  .choose-subscription-button {
    margin-top: 0.5rem;
    z-index: 1;
    cursor: pointer;
    border: none;
    border-radius: 24px;
    align-self: center;
    font-family: PPPangramSansRounded-Bold, moderat;
    line-height: 20px;
    font-size: 0.8rem;
    text-align: center;
    padding: 15px 25px;
    background: linear-gradient(180deg, rgba(224, 224, 224, 0) 0%, #d9d9d9 100%),
      rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.9),
      inset 0px 0px 5px rgba(255, 255, 255, 0.8);
    color: #5e5e5e;
    opacity: 1;
    -webkit-transition: box-shadow 0.3s ease;
    transition: box-shadow 0.3s ease;

    &:hover {
      opacity: 1;
      box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.9),
        inset 0px 0px 5px rgba(255, 255, 255, 0.8);
    }


    @media (min-width: 576px) {
      font-size: 1rem; // Standard font size for larger screens
    }
  }
}
// Media query for mobile screens
@media (max-width: 576px) {
  .subscription-section {
    padding: 0.8rem; // Even less padding on mobile
    max-width: 90%; // Take up most of the screen width
  }
}
  
  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: #ccd6f6;
    font-size: 0.875em;
  }
}
