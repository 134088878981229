.boost-modal {
  .emojiDisplay {
    font-size: 60px; // Adjust the size of the emojis
    text-align: center; // Center the emojis if the div becomes wider than the content
    margin-top: 2rem;
  }
  .boostBundleHeadline {
    text-align: center;
  }
  .originalPrice {
    text-decoration: line-through;
    margin-right: 5px;
  }
  .voteAndInputSideWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px; // Adjust the spacing as needed
  }
  .price{
    background: -webkit-linear-gradient(#353434, #0463f2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .boostTypeSelector {
    flex-grow: 1; // Allows the radio group to take available space
  }
  .banner {
    align-items: center;
    background-image: linear-gradient(92deg,rgba(214,165,253,.9),rgba(137,99,245,.9) 72%);
    border-radius: 3px 3px 20px 20px;
    display: flex;
    flex-direction: column;
    height: 24px;
    justify-content: flex-start;
    left: 0;
    margin: 0 auto;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    min-width: 70px;
    position: absolute;
    right: 0;
    top: -1px;
    z-index: 3;
  }
    // Centering the modal content
    display: flex;
    flex-direction: column;
    align-items: center; // Center children horizontally
    justify-content: center; // Center children vertically (if needed)
  
    // Ensuring the modal is wide enough on PCs
    @media (min-width: 769px) {
      width: 80%; // You can adjust this percentage based on your design needs
      width: 1100px; // Adjust this value to fit three items comfortably
      margin: auto; // Center the modal on the page
    }
  .gemGrid {
    display: grid;
    justify-items: center; // Center grid items horizontally
    grid-template-columns: repeat(3, 1fr); // Display items in a row of three for larger screens
    gap: 20px;
    padding: 20px;
    align-items: start;
    width: 100%; // Ensure the grid takes up the full width of the modal
    max-width: 1200px; // Maximum width of the grid to avoid overly stretched items
    margin: 0 auto; // Center the grid within the modal
  }
  .gemItem {
    display: flex;
    font-family: PPPangramSansRounded-Bold, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 200px;
    border-radius: 19px;
    position: relative;
    background: radial-gradient(140.83% 100% at 50.32% 100%, rgba(7, 201, 66, 0) 42.5%, rgba(7, 201, 66, 0.7) 100%), rgba(255, 255, 255, 0.1);
    .priceButton {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 5px;
      margin: 10px 0px;
      width: 200px;
      height: 40px;
      background: linear-gradient(rgb(255, 255, 255) 0%, rgb(217, 217, 217) 100%);
      box-shadow: rgba(209, 209, 209, 0.9) 0px 0px 15px, rgba(255, 255, 255, 0.4) 0px 0px 5px inset;
      border-radius: 14px;
      border: none;
      cursor: pointer;
      font-size: 14px; /* Consider making this smaller if necessary */
      line-height: 34px; /* Adjust line height to match button height for vertical centering */
      color: rgb(94, 94, 94);
    }
    
    /* Responsive adjustments for mobile screens */
    @media (max-width: 480px) {
      .priceButton {
        font-size: 10px; /* Smaller font size for mobile */
        padding: 0 10px; /* Add horizontal padding if needed */
        line-height: 30px; /* Adjust line height if button height changes */
        height: auto; /* Make height auto to accommodate larger text if needed */
        min-height: 34px; /* Ensure the button is not smaller than a certain height */
      }
    }
    .gems {
      font-weight: 400;
      font-size: 20px;
      line-height: 22px;
      color: rgb(255, 255, 255);
      font-family: PPPangramSansRounded-Bold, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
    }
    img {
      margin-top: 2rem;
      margin-bottom: 1rem;
      width: 120px;
      height: 80px;
      filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 5px 30px);
    }
  }
  @media (max-width: 768px) {
    .gemGrid {
      grid-template-columns: repeat(2, 1fr); // Display items in a row of three for larger screens
      gap: 15px;
      padding: 15px;
    }
    .boostBundleHeadline {
      text-align: center;
      margin-top: 20px;
      font-size: 1.5em; // Adjust size as needed
      // Add more styling as needed
    }
    .gemItem {
      width: 150px; // Adjust item width for smaller screens
      @media (min-width: 769px) { // Styles for desktop and larger devices
        margin: 0; // Remove any margin that might affect centering
      }
      .priceButton {
        width: 135px; // Adjust button width for smaller screens
        height: 30px; // Adjust button height for smaller screens
        font-size: 12px; // Adjust font size for better readability on smaller screens
      }

      .gems {
        font-size: 18px; // Adjust gems font size for smaller screens
      }

      img {
        margin-top: 1.5rem;
        margin-bottom: 0.75rem;
        width: 100px; // Adjust image width for smaller screens
        height: 60px; // Adjust image height for smaller screens
      }
    }
  }
  .saleGems{
    text-align: center;
    z-index: 3;
    position: absolute;
    width: 55px;
    height: 55px;
    border: none;
    top: 50px;
    right: 15px;
    display: flex;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background: radial-gradient(135.43% 1312% at -27.21% -40%, rgb(255, 221, 134) 0%, rgb(231, 110, 154) 100%);
    box-shadow: rgb(0, 0, 0) 0px 0px 3px inset, rgb(255, 255, 255) 0px 0px 13.5px inset;
    border-radius: 92px;
    transform: rotate(15deg);
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.01em;
    text-align: center;
    color: rgb(255, 255, 255);
    cursor: default;
    user-select: none;
  }
}