.memory-sidebar {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  background: rgba(255, 255, 255, 0.16);
  backdrop-filter: blur(25px);
  padding: 20px;
  color: white;
  transition: transform 0.3s ease-out;
  transform: translateX(-100%);
  overflow: auto;
  box-sizing: border-box;
  &.open {
    transform: translateX(0);
  }
}
@media (max-width: 600px) {
  .memory-sidebar {
      width: 100%;
  }
}
.close {
      border-radius: 14px;
      border: none;
      position: absolute;
      background: rgba(0, 0, 0, 0.08);
      top: 10px;
      right: 10px;
      cursor: pointer;
}

.headline {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.subHeadline {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.addButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  color: white;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
}

.memoryStore {
  display: flex;
  flex-direction: column;
}

.memoryGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
}

.memory {
  background-color: white;
  color: rgb(34, 34, 34);
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #f0f0f0;
  }
}

.fullMemory {
  display: flex;
  flex-direction: column;
}

.closeMemory {
  align-self: flex-start;
  background-color: transparent;
  border: none;
  color: #1565c0;
  cursor: pointer;
  margin-bottom: 20px;
}
.memoryActions {
  display: flex;
  justify-content: space-between;
}

.editButton, .deleteButton {
  border: none;
  background: none;
  cursor: pointer;
  color: rgb(34, 34, 34);
  transition: color 0.3s ease;

  &:hover {
      color: #1565c0;
  }
}