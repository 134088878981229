.ant-calendar-picker-input {
  font-family: 'PPPangramSansRounded-Bold' !important;
  font-size: 16px;
  /* add other styles as needed */
}
.ant-picker-input > input {
  font-size: 20px; /* Change to suit your needs */
  padding: 10px; /* Change to suit your needs */
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #5C75CC 0%, #203378 100%);
    height: 100vh;
    overflow-y: scroll;
    }
    .wrapper{
        color: white;
        background: linear-gradient(180deg, #5C75CC 0%, #203378 100%);
        height: 100vh;
        display: flex;
        overflow-y: scroll;
    }
    .datePicker{
      font-family: 'PPPangramSansRounded-Bold' !important;
      display: flex;
      justify-content: center;
    }
    .form-container {
      overflow: auto; /* Add scrolling */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #5C75CC 0%, #203378 100%);
    padding: 3rem;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    }
    
    .title {
    font-size: 2rem;
    margin-bottom: 2rem;
    }
    
    .form {
      input,
      select,
      textarea {
        color: white;
        font-size: 2rem;
        border: none; /* Remove the border */
        border-bottom: 1px solid #ccc; /* Add a bottom border */
        border-radius: 0;
        background: transparent;
        outline: none;
        width: 100%;
      }
      input:focus,
      select:focus,
      textarea:focus {
        color: white;
        outline: none;
        background: transparent; /* Set the background as transparent when the field is focused */
      }
      input:focus::placeholder,
textarea:focus::placeholder {
  color: white;
}
    margin-top: 8rem;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    }
    
    .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    }
    
    label {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    }
    
    
    select {
    margin-top: 0.25rem;
    }
    .pronouns{
      display: flex;
      justify-content: center;
    }
    .optionButton{
      outline: none;
      border: none !important;
      border-radius: 24px !important;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: PPPangramSansRounded-Bold, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
      font-size: 16px;
      font-weight: 400;
      height: 54px;
      min-width: 100px;
      width: 130px;
      border: 0px;
      border-radius: 24px;
      padding: 0px 30px;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(20px);
      color: rgb(255, 255, 255);
    
    }
    .optionButton:hover{
      background: rgba(255, 255, 255, 0.2);
      box-shadow: rgba(255, 255, 255, 0.3) 0px 0px 30px 0px, rgba(255, 255, 255, 0.2) 0px 0px 15px 0px inset;
      outline-offset: 0px;
    }
    .optionButtonSelected{
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 100px;
      width: 130px;
      cursor: pointer;
      font-family: PPPangramSansRounded-Bold, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
      font-size: 16px;
      font-weight: 400;
      height: 54px;
      border: 0px;
      border-radius: 24px;
      padding: 0px 30px;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(20px);
      color: rgb(255, 255, 255);
      background: rgba(255, 255, 255, 0.2);
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.3) 0px 0px 30px 0px, rgba(255, 255, 255, 0.2) 0px 0px 15px 0px inset;
  }
  .titleContainer{
    margin-bottom: 3rem;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .interestContainer{
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    justify-content: center;
    gap: 8px;
  }
  
    .button{
        margin-top: 2rem !important;
        z-index: 1;
        cursor: pointer;
        border: none;
        border-radius: 24px !important;
        align-self: center;
        font-family: PPPangramSansRounded-Bold, moderat;
        line-height: 20px;
        font-size: 20px;
        text-align: center;
        background: linear-gradient(180deg, rgba(224, 224, 224, 0) 0%, #d9d9d9 100%),
          rgba(255, 255, 255, 0.9);
        box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.9),
          inset 0px 0px 5px rgba(255, 255, 255, 0.8);
        color: #5e5e5e;
        opacity: 1;
        -webkit-transition: box-shadow 0.3s ease;
        transition: box-shadow 0.3s ease;
        align-items: center;
        height: 50px;
        width: 120px;
        font-size: 16px;
        margin-top: 0.5rem;
      }
    .button:hover {
        opacity: 1;
        box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.9),
          inset 0px 0px 5px rgba(255, 255, 255, 0.8);
      }
      .button:disabled {
        opacity: 0.3;
        box-shadow: none;
        cursor: not-allowed;
      }
    
    .error-message {
    color: red;
    font-size: 0.875rem;
    margin-top: -0.5rem;
    }