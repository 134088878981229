

$color-overlay: rgba(0, 0, 0, 0.6);
$color-white: #fff;
$color-light-grey: #f0f0f0;
$color-red: #ff5a5f;

.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  background-color: $color-overlay;
  z-index: 1000;

.modal-rel {
  position: relative;
  width: 300px;
  height: 400px;
  background: $color-white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  // SCSS for level bar
.level-container {
  margin-bottom: 1rem;
  background-color: $color-light-grey;
  border-radius: 10px;
  overflow: hidden;
}

.level-bar {
  height: 20px;
  background-color: $color-red;
  transition: width 0.3s ease-in-out;
}

.level-info {
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 600;
}
}

.modal-close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.modal-header {
  height: 60px;
  padding: 2rem;
  position: relative;
  margin-bottom: 3rem;
}

.modal-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 3px solid $color-white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

// Assuming $font-family and $color-red are defined elsewhere in your styles
.modal-title {
  font-size: 20px;
  margin: 0 auto; // This ensures it's centered horizontally
  font-weight: 600;
  color: $color-red;
  padding-top: 2rem; // Compensate for avatar overhang
  padding-bottom: 1rem;
  text-align: center;
  position: relative; // Relative positioning to place the close button absolutely
  padding-inline: 0.5rem; // Extra padding to accommodate the close button
}

// Adding a close button
.modal-close-button {
  font-size: 24px; // Size of the close button
  position: absolute;
  top: 1rem; // Distance from the top of the modal
  right: 1rem; // Distance from the side of the modal
  border: none;
  background: none;
  color: $color-red; // Use the same color for consistency
  cursor: pointer;
}

.modal-body {
  padding-bottom: 2rem;
}
.modal-button {
  background-color: #f0f0f0;
  border: none;
  border-radius: 20px;
  color: #303030;
  cursor: pointer;
  display: block;
  font-weight: 600;
  margin: 10px auto;
  padding: 10px 20px;
  width: 80%;

  &:hover {
    background-color: darken(#f0f0f0, 5%);
  }

  &:active {
    background-color: darken(#f0f0f0, 10%);
  }

  i {
    margin-right: 5px;
  }
}

.status-btn {
  background: $color-light-grey;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  width: 80%;
  font-weight: 600;
  display: block;
  margin: 10px auto;
  
  &--active {
    background: $color-red;
    color: $color-white;
  }
}

.fa-lock {
  margin-right: 5px;
}

// Adding transitions for button interactions
.status-btn, .modal-close-btn {
  transition: all 0.2s ease-in-out;
  
  &:hover {
    transform: translateY(-2px);
  }
  
  &:active {
    transform: translateY(1px);
  }
}
}