.profile-content-container{
  height: 100vh;
  background-image: linear-gradient(rgb(92, 117, 204) 0%, rgb(32, 51, 120));
  .profile-container {
    width: 60%;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.3);
    margin: 2rem auto;
    border-radius: 8px;
    overflow: hidden;
  }
  .back-button {
    background: none;
    border: none;
    color: #FFF; /* White color for the icon */
    font-size: 1.5rem; /* Adjust size as needed */
    cursor: pointer;
    position: absolute;
    top: 120px; /* Adjust based on design */
    left: 10%;
    padding: 0;
    z-index: 100; /* Ensure it's above other elements */
  }
  
  /* Adjustments for different screen sizes */
  @media (min-width: 768px) {
    .back-button {
      left: 25%; /* Adjust for larger screens */
    }
  }
  
  
  .profile-header {
    padding: 1rem;
    text-align: center;
    color: #ffffff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .profile-header img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 4px solid #ffffff;
    object-fit: cover;
  }
  
  .profile-header h1 {
    margin: 1rem 0 0.5rem;
    font-size: 1.8rem;
  }
  
  .profile-header p {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .profile-personality {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1rem;
  }
  
  .personality-tag {
    background-color: #e1ecf4;
    color: #5e5e5e;
    border-radius: 15px;
    padding: 0.3rem 1rem;
    margin: 0.5rem;
    font-size: 0.9rem;
  }
  
  .edit-buttons {
    text-align: center;
    padding: 1rem;
  }
  
  .edit-button {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    margin-top: 0.8rem;
    margin-left: 0.5rem;
    margin-right: 1rem;
    border: none;
    border-radius: 24px;
    font-family: PPPangramSansRounded-Bold, moderat;
    line-height: 20px;
    font-size: 14px;
    text-align: center;
    padding: 15px 25px;
    background: linear-gradient(
        180deg,
        rgba(224, 224, 224, 0) 0%,
        #d9d9d9 100%
      ),
      rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.9),
      inset 0px 0px 5px rgba(255, 255, 255, 0.8);
    color: #5e5e5e;
    opacity: 1;
    -webkit-transition: box-shadow 0.3s ease;
    transition: box-shadow 0.3s ease;
    max-width: 200px;
    max-height: 80px;
  }
  .edit-button:hover {
    opacity: 1;
    box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.9),
      inset 0px 0px 5px rgba(255, 255, 255, 0.8);
  }
  
  /* Add responsive design */
  @media (max-width: 768px) {
    .profile-container {
      width: 85%;
    }
  }
}
